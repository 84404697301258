/*-------------------------------------------------------------
    #BUTTONS
-----------------------------------------------------------------*/

@if $include__btn==true {
	// Block
	$btn__display				    :	inline		 							!default;
	$btn__border				    :	0px 									  !default;
	$btn__border-style			:	solid 									!default;
	$btn__border-color			:	color('terciary') 			!default;
	$btn__text-align			  :	center 									!default;
	$btn__white-space			  :	nowrap 									!default;
	$btn__vertical-align		:	middle 									!default;
	$btn__padding-x				  :	rem(32)									!default;
	$btn__padding-y				  :	rem(16)									!default;
	$btn__font-family			  : obviously, sans-serif	  !default;
	$btn__font-size				  :	rem($ns-font-size) 			!default;
	$btn__line-height			  :	1.2										  !default;
	$btn__font-weight			  :	700 									  !default;
	$btn__color					    :	color('white')					!default;
	$btn__background			  :	color('terciary')				!default;
	$btn__cursor				    :	pointer 								!default;
	$btn__border-radius			:	0px									  !default;
	$btn__user-select			  :	none 									  !default;
	$btn__hover-transition		:	color .15s ease-in-out,
									background-color .15s ease-in-out,
									border-color .15s ease-in-out,
									box-shadow .15s ease-in-out 			!default;
	$btn__modifiers-colors		:	default primary success danger warning info darken;

	.o-btn,
  .o-btn.p-button,
	%btn {
		display			    :	$btn__display;
		padding			    :	$btn__padding-y $btn__padding-x;
		font-family		  :	$btn__font-family;
		font-size		    :	$btn__font-size;
		line-height		  :	$btn__line-height;
		font-weight		  :	$btn__font-weight;
		color			      :	$btn__color;
		text-align		  :	$btn__text-align;
		vertical-align	:	$btn__vertical-align;
		border			    :	$btn__border $btn__border-style $btn__border-color;
		cursor			    :	$btn__cursor !important;
		background		  : $btn__background;
		@include border-radius($btn__border-radius);
		@include transition($btn__hover-transition);
		@include user-select($btn__user-select);
    position: relative;
    clip-path: polygon(0 20%, 4% 0, 100% 0, 100% 80%, 96% 100%, 0% 100%);

    /**
      primeng button
    */

    &.p-button:enabled:hover {
      color: color('terciary');
      background-color: color('secondary');
      opacity: 1;
    }

		/**
      * Actions
    */

		&:hover {
			text-decoration: none;
      opacity: 1;
      color: color('terciary');
      background-color: color('secondary');
		}

		&:active,
		&:focus {
			outline: none;
      box-shadow: 0 0 0 0.2rem color('terciary', 0.4);
		}

		/**
         * Disabled
		 */

		&:disabled {
			color: color('white');
			background-color: color('grey-5');
			border-color: color('grey-5');
      cursor: default !important;
      pointer-events: unset;
      opacity: 1;

      &:hover {
        &:before {
          content: none;
        }
      }
		}

    &[disabled="true"] {
      cursor: default;
    }

		/**
         * Modifiers others
		 */

		&--block {
			display: block;
			width: 100%;
		}

    &--block-auto {
      display: block !important;
    }

		&--link {
			border: none;
			color: color('black');
			text-decoration: underline;
			background: none;
			font-family: $ns-font-default;
			padding: 0;

      &:hover {
        opacity: 1;
        color: color('black');
        background: none;
      }
		}

    &--link-italic {
      border: none;
			text-decoration: underline;
			padding: 0;
			font-style: italic;
			font-family: $ns-font-default;
      background-color: transparent;
      color: color('secondary');

			&:hover {
				text-decoration: underline;
        color: color('primary');
        background-color: transparent;
			}
    }

    &--secondary {
      border-radius: 0;
      background-color: color('primary');
      padding: rem(11) rem(26);
    }

    &--link-black {
			border: none;
			text-decoration: underline;
			padding: 0;
			font-family: $ns-font-default;
      background-color: transparent;
      color: color('black');

			&:hover {
				text-decoration: underline;
        background-color: transparent;
        color: color('black');
			}
		}

    &--link-normal {
      border: none;
			color: color('quaternary');
			text-decoration: underline;
			background: none;
			font-family: $ns-font-default;
      font-weight: normal;
			padding: 0;

      &:hover {
        opacity: 1;
        color: color('black');
        background: none;
      }
    }

    /**
      Modifiers separations
    */

    &--mt-small {
      margin-top: rem(35);
    }
	}
}

// END IF
