.x-axis {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 2rem;
  align-items: center;
  font-size: 0.85rem;
  z-index: 3;
  padding-inline: 1.35rem;

  div {
    width: 100%;
    text-align: center;
  }
  &--yesterday {
    text-align: center;
    height: 2rem;
    display: flex;
    align-items: center;
    position: absolute;
    margin-left: 680px;
  }
}

.marker {
  .line {
    border-left: 2px dotted #FFC600 !important;
  }
}

.marker-circle {
  background-color: #FFC600;
  color: black;
}

.marker-text {
  position: absolute;
  top: 140px;
  left: 50%;
  transform: translate(-50%, 10px);
  font-size: 1.1rem;
  z-index: 100;
  color: #FFC600;
}

.marker-button-wrap {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;

  .marker-button {
    button {
      width: 167px;
      display: inherit;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.timeline {
  display: flex;
  justify-content: flex-end;
}

.graph {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
  margin-right: 0.5rem;
}

.chart {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  width: 94%;
  position: relative;
}

.x-axis-l14h-neighbors {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 2rem;
  align-items: center;
  font-size: 0.85rem;
  z-index: 3;

  div {
    width: 100%;
    text-align: center;
  }
}

.y-axis {
  padding-top: 2rem;
  z-index: 3;

  &--left {
    width: auto;
    font-size: 0.85rem;
    text-align: end;

    div {
      display: flex;
      flex-direction: column;
      height: 100%;

      p {
        padding: 0.25rem;
        height: 100%;
      }
    }
  }

  &--right {
    .checkboxGroup {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
    }

  }
}

.data {
  display: flex;
  align-items: flex-end;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  height: 29.6px;
  z-index: 2;

  &--bar {
    justify-content: flex-end;

    div {
      width: 30px;
      background-color: blue;
    }
  }

  &--icons {
    div {

      height: 21px;

      img {
        position: absolute;
        height: 21px;
      }
    }
  }
}

.chart-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  table {
    width: 100%;

    tr {
      display: flex;

      .column {
        float: left;
        height: 29.6px;
        border: 1px solid #FFFFFF;
      }

      .column:nth-child(odd) {
        background-color: #EEEEEE;
      }

      .column:nth-child(even) {
        background-color: #F3F3F3;
      }
    }
  }

  &--l14h_neighbors {
    .column {
      width: calc(100% / 15);
    }
  }

  &--l4w {
    .column  {
      width: 25%;
    }
  }
}

.marker {
  position: absolute;
  top: -5px;
  width: 0.1px;
  height: 105%;
  transform-origin: top center;
  cursor: move;
  z-index: 100;

  .line {
    border: none;
    border-left: 2px dotted red !important;
    width: 2px;
    height: 100%;
  }
}

.marker-circle {
  position: absolute;
  top: 100%;
  transform: translate(-50%, 0);
  left: 1px;
  width: 25px;
  height: 25px;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  cursor: move;
  z-index: 100;
  font-size: 1.2rem;
}

.marker-text {
  position: absolute;
  top: 265px;
  left: 50%;
  transform: translate(-50%, 10px);
  font-size: 1.1rem;
  z-index: 100;
  color: red;
}
