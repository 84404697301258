/*-------------------------------------------------------------
    #check
-----------------------------------------------------------------*/

.o-check {
  margin-right: rem(6);

  .p-checkbox-box {
    border-radius: 0;
    border-color: color('grey-1');

    &.p-highlight {
      background-color: color('terciary');
      border-color: color('terciary');
    }
  }

  &.p-checkbox {
    &:not(.p-checkbox-disabled) .p-checkbox-box {
      &.p-highlight:hover {
        border-color: color('terciary');
        background: color('terciary');
      }

      &.p-focus {
        border-color: color('terciary');
        box-shadow: 0 0 0 0.2rem color('terciary', 0.4);
      }

      &:hover {
        border-color: color('terciary');
      }
    }
  }

  .p-checkbox {
    &:not(.p-checkbox-disabled) .p-checkbox-box {
      &.p-highlight:hover {
        border-color: color('terciary');
        background: color('terciary');
      }

      &.p-focus {
        border-color: color('terciary');
        box-shadow: 0 0 0 0.2rem color('terciary', 0.4);
      }

      &:hover {
        border-color: color('terciary');
      }
    }
  }

  &__label {
    a {
      color: color('black');
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    &--inline {
      display: inline !important;
      vertical-align: middle;

      p {
        display: inline;
        color: color('black');
      }
    }
  }

  &--inline-txt {
    margin-right: rem(4);
    vertical-align: middle;
    margin-bottom: 0 !important;

    & + .o-check__label--inline + .o-inputtext__input {
      margin-top: rem(8);

      & + .alert-danger {
        margin-bottom: 0;
      }
    }
  }
}

.l1 {
  .o-check {
    margin-bottom: rem(5);

    &__wrapper {
      &--inline-block {
        display: inline-block;
        margin-right: rem(30);
        margin-bottom: rem(7);
      }
    }

    .p-checkbox-box {
      border-radius: 0;
      border-color: color('grey-1');

      &.p-highlight {
        background-color: color('terciary');
        border-color: color('terciary');
      }
    }

    .p-checkbox {
      &:not(.p-checkbox-disabled) .p-checkbox-box {
        &.p-highlight:hover {
          border-color: color('terciary');
          background: color('terciary');
        }

        &.p-focus {
          border-color: color('terciary');
          box-shadow: 0 0 0 0.2rem color('terciary', 0.4);
        }

        &:hover {
          border-color: color('terciary');
        }
      }
    }

    .p-checkbox-label {
      font-family: $ns-font-default;
      font-weight: bold;
    }
  }
}

.o-check-red {
  .p-checkbox {
    .p-checkbox-box:not(:checked) {
      border-color: red !important;
    }
  }
}
