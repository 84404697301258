@charset "UTF-8";
/* Customizations to the designer theme should be defined here */
:root {
	--primary-color: #FFC107;
	--text-color: #495057;
	--text-color-secondary: #6c757d;
	--primary-color-text: #212529;
	--invalid-error: #f44336;
	--shadow-color: #ffe69c;
	--background-panel: #FFF3E0;
	--panel-border: #ced4da;
	--element-hover: #d29d00;
	--button-hover: #ecb100;
    --content-padding: 1rem;
	--inline-spacing: 0.5rem;

	--surface-a: #ffffff;
	--surface-b: #f8f9fa;
	--surface-c: #e9ecef;
	--surface-d: #dee2e6;
	--surface-e: #ffffff;
	--surface-f: #ffffff;
	--font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
	--surface-0: #ffffff;
	--surface-50: #FAFAFA;
	--surface-100: #F5F5F5;
	--surface-200: #EEEEEE;
	--surface-300: #E0E0E0;
	--surface-400: #BDBDBD;
	--surface-500: #9E9E9E;
	--surface-600: #757575;
	--surface-700: #616161;
	--surface-800: #424242;
	--surface-900: #212121;

	--blue-50: #f4fafe;
	--blue-100: #cae6fc;
	--blue-200: #a0d2fa;
	--blue-300: #75bef8;
	--blue-400: #4baaf5;
	--blue-500: #2196f3;
	--blue-600: #1c80cf;
	--blue-700: #1769aa;
	--blue-800: #125386;
	--blue-900: #0d3c61;
	--green-50: #f6fbf6;
	--green-100: #d4ecd5;
	--green-200: #b2ddb4;
	--green-300: #90cd93;
	--green-400: #6ebe71;
	--green-500: #4caf50;
	--green-600: #419544;
	--green-700: #357b38;
	--green-800: #2a602c;
	--green-900: #1e4620;
	--yellow-50: #fffcf5;
	--yellow-100: #fef0cd;
	--yellow-200: #fde4a5;
	--yellow-300: #fdd87d;
	--yellow-400: #fccc55;
	--yellow-500: #fbc02d;
	--yellow-600: #d5a326;
	--yellow-700: #b08620;
	--yellow-800: #8a6a19;
	--yellow-900: #644d12;
	--cyan-50: #f2fcfd;
	--cyan-100: #c2eff5;
	--cyan-200: #91e2ed;
	--cyan-300: #61d5e4;
	--cyan-400: #30c9dc;
	--cyan-500: #00bcd4;
	--cyan-600: #00a0b4;
	--cyan-700: #008494;
	--cyan-800: #006775;
	--cyan-900: #004b55;
	--pink-50: #fef4f7;
	--pink-100: #fac9da;
	--pink-200: #f69ebc;
	--pink-300: #f1749e;
	--pink-400: #ed4981;
	--pink-500: #e91e63;
	--pink-600: #c61a54;
	--pink-700: #a31545;
	--pink-800: #801136;
	--pink-900: #5d0c28;
	--indigo-50: #f5f6fb;
	--indigo-100: #d1d5ed;
	--indigo-200: #acb4df;
	--indigo-300: #8893d1;
	--indigo-400: #6372c3;
	--indigo-500: #3f51b5;
	--indigo-600: #36459a;
	--indigo-700: #2c397f;
	--indigo-800: #232d64;
	--indigo-900: #192048;
	--teal-50: #f2faf9;
	--teal-100: #c2e6e2;
	--teal-200: #91d2cc;
	--teal-300: #61beb5;
	--teal-400: #30aa9f;
	--teal-500: #009688;
	--teal-600: #008074;
	--teal-700: #00695f;
	--teal-800: #00534b;
	--teal-900: #003c36;
	--orange-50: #fff8f2;
	--orange-100: #fde0c2;
	--orange-200: #fbc791;
	--orange-300: #f9ae61;
	--orange-400: #f79530;
	--orange-500: #f57c00;
	--orange-600: #d06900;
	--orange-700: #ac5700;
	--orange-800: #874400;
	--orange-900: #623200;
	--bluegray-50: #f7f9f9;
	--bluegray-100: #d9e0e3;
	--bluegray-200: #bbc7cd;
	--bluegray-300: #9caeb7;
	--bluegray-400: #7e96a1;
	--bluegray-500: #56717d;
	--bluegray-600: #526a76;
	--bluegray-700: #435861;
	--bluegray-800: #35454c;
	--bluegray-900: #263238;
	--purple-50: #faf4fb;
	--purple-100: #e7cbec;
	--purple-200: #d4a2dd;
	--purple-300: #c279ce;
	--purple-400: #af50bf;
	--purple-500: #9c27b0;
	--purple-600: #852196;
	--purple-700: #6d1b7b;
	--purple-800: #561561;
	--purple-900: #3e1046;
}
* {
	box-sizing: border-box;
}
.p-component {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-size: 1rem;
	font-weight: normal;
	&:disabled {
		opacity: 0.6;
	}
}
.p-component-overlay {
	background-color: rgba(0, 0, 0, 0.4);
	transition-duration: 0.2s;
}
.p-disabled {
	opacity: 0.6;
}
.p-error {
	color: var(--invalid-error);
}
.p-text-secondary {
	color: var(--text-color-secondary);
}
.pi {
	font-size: 1rem;
}
.p-link {
	font-size: 1rem;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	border-radius: 3px;
	&:focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem var(--shadow-color);
	}
}
.p-autocomplete {
	.p-autocomplete-loader {
		right: 0.5rem;
	}
	.p-autocomplete-multiple-container {
		padding: 0.25rem 0.5rem;
		&:not(.p-disabled) {
			&:hover {
				border-color: var(--primary-color);
			}
		}
		&:not(.p-disabled).p-focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: 0 0 0 0.2rem var(--shadow-color);
			border-color: var(--primary-color);
		}
		.p-autocomplete-input-token {
			padding: 0.25rem 0;
			input {
				font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
				font-size: 1rem;
				color: var(--text-color);
				padding: 0;
				margin: 0;
			}
		}
		.p-autocomplete-token {
			padding: 0.25rem 0.5rem;
			margin-right: 0.5rem;
			background: var(--background-panel);
			color: var(--text-color);
			border-radius: 3px;
			.p-autocomplete-token-icon {
				margin-left: 0.5rem;
			}
		}
	}
}
.p-autocomplete.p-autocomplete-dd {
	.p-autocomplete-loader {
		right: 2.857rem;
	}
}
p-autocomplete.ng-dirty.ng-invalid {
	>.p-autocomplete {
		>.p-inputtext {
			border-color: var(--invalid-error);
		}
	}
}
.p-autocomplete-panel {
	background: var(--surface-0);
	color: var(--text-color);
	border: 0 none;
	border-radius: 3px;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
	.p-autocomplete-items {
		padding: 0.5rem 0;
		.p-autocomplete-item {
			margin: 0;
			padding: 0.5rem 1rem;
			border: 0 none;
			color: var(--text-color);
			background: transparent;
			transition: box-shadow 0.2s;
			border-radius: 0;
			&:hover {
				color: var(--text-color);
				background: var(--surface-c);
			}
		}
		.p-autocomplete-item.p-highlight {
			color: var(--text-color);
			background: var(--background-panel);
		}
		.p-autocomplete-item-group {
			margin: 0;
			padding: 0.75rem 1rem;
			color: var(--text-color);
			background: var(--surface-0);
			font-weight: 600;
		}
	}
}
p-calendar.ng-dirty.ng-invalid {
	>.p-calendar {
		>.p-inputtext {
			border-color: var(--invalid-error);
		}
	}
}
.p-datepicker {
	padding: 0.5rem;
	background: var(--surface-0);
	color: var(--text-color);
	border: 1px solid var(--panel-border);
	border-radius: 3px;
	&:not(.p-datepicker-inline) {
		background: var(--surface-0);
		border: 0 none;
		box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
		.p-datepicker-header {
			background: var(--surface-0);
		}
	}
	.p-datepicker-header {
		padding: 0.5rem;
		color: var(--text-color);
		background: var(--surface-0);
		font-weight: 600;
		margin: 0;
		border-bottom: 1px solid var(--surface-d);
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
		.p-datepicker-prev {
			width: 2rem;
			height: 2rem;
			color: var(--text-color-secondary);
			border: 0 none;
			background: transparent;
			border-radius: 50%;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			&:enabled {
				&:hover {
					color: var(--text-color);
					border-color: transparent;
					background: var(--surface-c);
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: 0 0 0 0.2rem var(--shadow-color);
			}
		}
		.p-datepicker-next {
			width: 2rem;
			height: 2rem;
			color: var(--text-color-secondary);
			border: 0 none;
			background: transparent;
			border-radius: 50%;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			&:enabled {
				&:hover {
					color: var(--text-color);
					border-color: transparent;
					background: var(--surface-c);
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: 0 0 0 0.2rem var(--shadow-color);
			}
		}
		.p-datepicker-title {
			line-height: 2rem;
			select {
				transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					box-shadow: 0 0 0 0.2rem var(--shadow-color);
					border-color: var(--primary-color);
				}
			}
			.p-datepicker-month {
				margin-right: 0.5rem;
			}
		}
	}
	table {
		font-size: 1rem;
		margin: 0.5rem 0;
		th {
			padding: 0.5rem;
			>span {
				width: 2.5rem;
				height: 2.5rem;
			}
		}
		td {
			padding: 0.5rem;
			>span {
				width: 2.5rem;
				height: 2.5rem;
				border-radius: 50%;
				transition: box-shadow 0.2s;
				border: 1px solid transparent;
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					box-shadow: 0 0 0 0.2rem var(--shadow-color);
				}
			}
			>span.p-highlight {
				color: var(--text-color);
				background: var(--background-panel);
			}
		}
		td.p-datepicker-today {
			>span {
				background: var(--panel-border);
				color: var(--text-color);
				border-color: transparent;
			}
			>span.p-highlight {
				color: var(--text-color);
				background: var(--background-panel);
			}
		}
	}
	.p-datepicker-buttonbar {
		padding: 1rem 0;
		border-top: 1px solid var(--surface-d);
		.p-button {
			width: auto;
		}
	}
	.p-timepicker {
		border-top: 1px solid var(--surface-d);
		padding: 0.5rem;
		button {
			width: 2rem;
			height: 2rem;
			color: var(--text-color-secondary);
			border: 0 none;
			background: transparent;
			border-radius: 50%;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			&:enabled {
				&:hover {
					color: var(--text-color);
					border-color: transparent;
					background: var(--surface-c);
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: 0 0 0 0.2rem var(--shadow-color);
			}
			&:last-child {
				margin-top: 0.2em;
			}
		}
		span {
			font-size: 1.25rem;
		}
		>div {
			padding: 0 0.5rem;
		}
	}
	.p-monthpicker {
		margin: 0.5rem 0;
		.p-monthpicker-month {
			padding: 0.5rem;
			transition: box-shadow 0.2s;
			border-radius: 3px;
		}
		.p-monthpicker-month.p-highlight {
			color: var(--text-color);
			background: var(--background-panel);
		}
	}
	&:not(.p-disabled) {
		table {
			td {
				span {
					&:not(.p-highlight) {
						&:not(.p-disabled) {
							&:hover {
								background: var(--surface-c);
							}
							&:focus {
								outline: 0 none;
								outline-offset: 0;
								box-shadow: 0 0 0 0.2rem var(--shadow-color);
							}
						}
					}
				}
			}
		}
		.p-monthpicker {
			.p-monthpicker-month {
				&:not(.p-highlight) {
					&:not(.p-disabled) {
						&:hover {
							background: var(--surface-c);
						}
						&:focus {
							outline: 0 none;
							outline-offset: 0;
							box-shadow: 0 0 0 0.2rem var(--shadow-color);
						}
					}
				}
			}
		}
	}
}
.p-datepicker.p-datepicker-timeonly {
	.p-timepicker {
		border-top: 0 none;
	}
}
.p-datepicker.p-datepicker-multiple-month {
	.p-datepicker-group {
		border-right: 1px solid var(--surface-d);
		padding-right: 0.5rem;
		padding-left: 0.5rem;
		padding-top: 0;
		padding-bottom: 0;
		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
			border-right: 0 none;
		}
	}
}
.p-cascadeselect {
	background: var(--surface-0);
	border: 1px solid var(--panel-border);
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	border-radius: 3px;
	&:not(.p-disabled) {
		&:hover {
			border-color: var(--primary-color);
		}
	}
	&:not(.p-disabled).p-focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem var(--shadow-color);
		border-color: var(--primary-color);
	}
	.p-cascadeselect-label {
		background: transparent;
		border: 0 none;
		padding: 0.5rem 0.5rem;
		&:enabled {
			&:focus {
				outline: 0 none;
				box-shadow: none;
			}
		}
	}
	.p-cascadeselect-label.p-placeholder {
		color: var(--text-color-secondary);
	}
	.p-cascadeselect-trigger {
		background: transparent;
		color: var(--text-color-secondary);
		width: 2.357rem;
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
	}
}
.p-cascadeselect-panel {
	background: var(--surface-0);
	color: var(--text-color);
	border: 0 none;
	border-radius: 3px;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
	.p-cascadeselect-items {
		padding: 0.5rem 0;
		.p-cascadeselect-item {
			margin: 0;
			border: 0 none;
			color: var(--text-color);
			background: transparent;
			transition: box-shadow 0.2s;
			border-radius: 0;
			.p-cascadeselect-item-content {
				padding: 0.5rem 1rem;
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					box-shadow: inset 0 0 0 0.15rem var(--shadow-color);
				}
			}
			&:not(.p-highlight) {
				&:not(.p-disabled) {
					&:hover {
						color: var(--text-color);
						background: var(--surface-c);
					}
				}
			}
			.p-cascadeselect-group-icon {
				font-size: 0.875rem;
			}
		}
		.p-cascadeselect-item.p-highlight {
			color: var(--text-color);
			background: var(--background-panel);
		}
	}
}
p-cascadeselect.ng-dirty.ng-invalid {
	>.p-cascadeselect {
		border-color: var(--invalid-error);
	}
}
.p-input-filled {
	.p-cascadeselect {
		background: var(--surface-b);
		&:not(.p-disabled) {
			&:hover {
				background-color: var(--surface-b);
			}
		}
		&:not(.p-disabled).p-focus {
			background-color: var(--surface-0);
		}
	}
	.p-checkbox {
		.p-checkbox-box {
			background-color: var(--surface-b);
		}
		.p-checkbox-box.p-highlight {
			background: var(--primary-color);
		}
		&:not(.p-checkbox-disabled) {
			.p-checkbox-box {
				&:hover {
					background-color: var(--surface-b);
				}
			}
			.p-checkbox-box.p-highlight {
				&:hover {
					background: var(--element-hover);
				}
			}
		}
	}
	.p-dropdown {
		background: var(--surface-b);
		&:not(.p-disabled) {
			&:hover {
				background-color: var(--surface-b);
			}
		}
		&:not(.p-disabled).p-focus {
			background-color: var(--surface-0);
		}
	}
	.p-inputtext {
		background-color: var(--surface-b);
		&:enabled {
			&:hover {
				background-color: var(--surface-b);
			}
			&:focus {
				background-color: var(--surface-0);
			}
		}
	}
	.p-multiselect {
		background: var(--surface-b);
		&:not(.p-disabled) {
			&:hover {
				background-color: var(--surface-b);
			}
		}
		&:not(.p-disabled).p-focus {
			background-color: var(--surface-0);
		}
	}
	.p-radiobutton {
		.p-radiobutton-box {
			background-color: var(--surface-b);
			&:not(.p-disabled) {
				&:hover {
					background-color: var(--surface-b);
				}
			}
		}
		.p-radiobutton-box.p-highlight {
			background: var(--primary-color);
			&:not(.p-disabled) {
				&:hover {
					background: var(--element-hover);
				}
			}
		}
	}
}
.p-checkbox {
	width: 20px;
	height: 20px;
	.p-checkbox-box {
		border: 2px solid var(--panel-border);
		background: var(--surface-0);
		width: 20px;
		height: 20px;
		color: var(--text-color);
		border-radius: 3px;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		.p-checkbox-icon {
			transition-duration: 0.2s;
			color: var(--primary-color-text);
			font-size: 14px;
		}
	}
	.p-checkbox-box.p-highlight {
		border-color: var(--primary-color);
		background: var(--primary-color);
	}
	&:not(.p-checkbox-disabled) {
		.p-checkbox-box {
			&:hover {
				border-color: var(--primary-color);
			}
		}
		.p-checkbox-box.p-focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: 0 0 0 0.2rem var(--shadow-color);
			border-color: var(--primary-color);
		}
		.p-checkbox-box.p-highlight {
			&:hover {
				border-color: var(--element-hover);
				background: var(--element-hover);
				color: var(--primary-color-text);
			}
		}
	}
}
p-checkbox.ng-dirty.ng-invalid {
	>.p-checkbox {
		>.p-checkbox-box {
			border-color: var(--invalid-error);
		}
	}
}
.p-checkbox-label {
	margin-left: 0.5rem;
}
.p-chips {
	.p-chips-multiple-container {
		padding: 0.25rem 0.5rem;
		&:not(.p-disabled) {
			&:hover {
				border-color: var(--primary-color);
			}
		}
		&:not(.p-disabled).p-focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: 0 0 0 0.2rem var(--shadow-color);
			border-color: var(--primary-color);
		}
		.p-chips-token {
			padding: 0.25rem 0.5rem;
			margin-right: 0.5rem;
			background: var(--background-panel);
			color: var(--text-color);
			border-radius: 3px;
			.p-chips-token-icon {
				margin-left: 0.5rem;
			}
		}
		.p-chips-input-token {
			padding: 0.25rem 0;
			input {
				font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
				font-size: 1rem;
				color: var(--text-color);
				padding: 0;
				margin: 0;
			}
		}
	}
}
p-chips.ng-dirty.ng-invalid {
	>.p-chips {
		>.p-inputtext {
			border-color: var(--invalid-error);
		}
	}
}
.p-colorpicker-preview {
	width: 2rem;
	height: 2rem;
}
.p-fluid {
	.p-colorpicker-preview.p-inputtext {
		width: 2rem;
		height: 2rem;
	}
	.p-inputgroup {
		.p-button {
			width: auto;
		}
		.p-button.p-button-icon-only {
			width: 2.357rem;
		}
	}
	.p-button {
		width: 100%;
	}
	.p-button-icon-only {
		width: 2.357rem;
	}
	.p-buttonset {
		display: flex;
		.p-button {
			flex: 1;
		}
	}
}
.p-colorpicker-panel {
	background: #323232;
	border-color: #191919;
	.p-colorpicker-color-handle {
		border-color: var(--surface-0);
	}
	.p-colorpicker-hue-handle {
		border-color: var(--surface-0);
	}
}
.p-colorpicker-overlay-panel {
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-dropdown {
	width: 100%;
	background: var(--surface-0);
	border: 1px solid var(--panel-border);
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	border-radius: 3px;
	&:not(.p-disabled) {
		&:hover {
			border-color: var(--primary-color);
		}
	}
	&:not(.p-disabled).p-focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem var(--shadow-color);
		border-color: var(--primary-color);
	}
	.p-dropdown-label {
		background: transparent;
		border: 0 none;
		&:enabled {
			&:focus {
				outline: 0 none;
				box-shadow: none;
			}
		}
	}
	.p-dropdown-label.p-placeholder {
		color: var(--text-color-secondary);
	}
	.p-dropdown-trigger {
		background: transparent;
		color: var(--text-color-secondary);
		width: 2.357rem;
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
	}
	.p-dropdown-clear-icon {
		color: var(--text-color-secondary);
		right: 2.357rem;
	}
}
.p-dropdown.p-dropdown-clearable {
	.p-dropdown-label {
		padding-right: 1.5rem;
	}
}
.p-dropdown-panel {
	background: var(--surface-0);
	color: var(--text-color);
	border: 0 none;
	border-radius: 3px;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
	.p-dropdown-header {
		padding: 0.5rem 1rem;
		border-bottom: 0 none;
		color: var(--text-color);
		background: var(--surface-b);
		margin: 0;
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
		.p-dropdown-filter {
			padding-right: 1.5rem;
		}
		.p-dropdown-filter-icon {
			right: 0.5rem;
			color: var(--text-color-secondary);
		}
	}
	.p-dropdown-items {
		padding: 0.5rem 0;
		.p-dropdown-item {
			margin: 0;
			padding: 0.5rem 1rem;
			border: 0 none;
			color: var(--text-color);
			background: transparent;
			transition: box-shadow 0.2s;
			border-radius: 0;
			&:not(.p-highlight) {
				&:not(.p-disabled) {
					&:hover {
						color: var(--text-color);
						background: var(--surface-c);
					}
				}
			}
		}
		.p-dropdown-item.p-highlight {
			color: var(--text-color);
			background: var(--background-panel);
		}
		.p-dropdown-empty-message {
			padding: 0.5rem 1rem;
			color: var(--text-color);
			background: transparent;
		}
		.p-dropdown-item-group {
			margin: 0;
			padding: 0.75rem 1rem;
			color: var(--text-color);
			background: var(--surface-0);
			font-weight: 600;
		}
	}
}
p-dropdown.ng-dirty.ng-invalid {
	>.p-dropdown {
		border-color: var(--invalid-error);
	}
}
.p-editor-container {
	.p-editor-toolbar {
		background: var(--surface-b);
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
	}
	.p-editor-toolbar.ql-snow {
		border: 1px solid var(--surface-d);
		.ql-stroke {
			stroke: var(--text-color-secondary);
		}
		.ql-fill {
			fill: var(--text-color-secondary);
		}
		.ql-picker {
			.ql-picker-label {
				border: 0 none;
				color: var(--text-color-secondary);
				&:hover {
					color: var(--text-color);
					.ql-stroke {
						stroke: var(--text-color);
					}
					.ql-fill {
						fill: var(--text-color);
					}
				}
			}
		}
		.ql-picker.ql-expanded {
			.ql-picker-label {
				color: var(--text-color);
				.ql-stroke {
					stroke: var(--text-color);
				}
				.ql-fill {
					fill: var(--text-color);
				}
			}
			.ql-picker-options {
				background: var(--surface-0);
				border: 0 none;
				box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
				border-radius: 3px;
				padding: 0.5rem 0;
				.ql-picker-item {
					color: var(--text-color);
					&:hover {
						color: var(--text-color);
						background: var(--surface-c);
					}
				}
			}
			&:not(.ql-icon-picker) {
				.ql-picker-item {
					padding: 0.5rem 1rem;
				}
			}
		}
	}
	.p-editor-content {
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
		.ql-editor {
			background: var(--surface-0);
			color: var(--text-color);
			border-bottom-right-radius: 3px;
			border-bottom-left-radius: 3px;
		}
	}
	.p-editor-content.ql-snow {
		border: 1px solid var(--surface-d);
	}
	.ql-snow.ql-toolbar {
		button {
			&:hover {
				color: var(--text-color);
				.ql-stroke {
					stroke: var(--text-color);
				}
				.ql-fill {
					fill: var(--text-color);
				}
			}
			&:focus {
				color: var(--text-color);
				.ql-stroke {
					stroke: var(--text-color);
				}
				.ql-fill {
					fill: var(--text-color);
				}
			}
		}
		button.ql-active {
			color: var(--primary-color);
			.ql-stroke {
				stroke: var(--primary-color);
			}
			.ql-fill {
				fill: var(--primary-color);
			}
			.ql-picker-label {
				color: var(--primary-color);
			}
		}
		.ql-picker-label.ql-active {
			color: var(--primary-color);
			.ql-stroke {
				stroke: var(--primary-color);
			}
			.ql-fill {
				fill: var(--primary-color);
			}
			.ql-picker-label {
				color: var(--primary-color);
			}
		}
		.ql-picker-item.ql-selected {
			color: var(--primary-color);
			.ql-stroke {
				stroke: var(--primary-color);
			}
			.ql-fill {
				fill: var(--primary-color);
			}
			.ql-picker-label {
				color: var(--primary-color);
			}
		}
	}
}
.p-inputgroup-addon {
	background: var(--surface-c);
	color: var(--text-color-secondary);
	border-top: 1px solid var(--panel-border);
	border-left: 1px solid var(--panel-border);
	border-bottom: 1px solid var(--panel-border);
	padding: 0.5rem 0.5rem;
	min-width: 2.357rem;
	&:last-child {
		border-right: 1px solid var(--panel-border);
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
	}
	&:first-child {
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
	}
}
.p-inputgroup {
	>.p-component {
		border-radius: 0;
		margin: 0;
		+ {
			.p-inputgroup-addon {
				border-left: 0 none;
			}
		}
		&:focus {
			z-index: 1;
			~ {
				label {
					z-index: 1;
				}
			}
		}
	}
	>.p-float-label {
		>.p-component {
			border-radius: 0;
			margin: 0;
			+ {
				.p-inputgroup-addon {
					border-left: 0 none;
				}
			}
			&:focus {
				z-index: 1;
				~ {
					label {
						z-index: 1;
					}
				}
			}
		}
	}
	button {
		&:first-child {
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
		}
		&:last-child {
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
		}
	}
	input {
		&:first-child {
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
		}
		&:last-child {
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
		}
	}
	.p-float-label {
		&:first-child {
			input {
				border-top-left-radius: 3px;
				border-bottom-left-radius: 3px;
			}
		}
		&:last-child {
			input {
				border-top-right-radius: 3px;
				border-bottom-right-radius: 3px;
			}
		}
	}
}
p-inputmask.ng-dirty.ng-invalid {
	>.p-inputtext {
		border-color: var(--invalid-error);
	}
}
p-inputnumber.ng-dirty.ng-invalid {
	>.p-inputnumber {
		>.p-inputtext {
			border-color: var(--invalid-error);
		}
	}
}
.p-inputswitch {
	width: 3rem;
	height: 1.75rem;
	.p-inputswitch-slider {
		background: var(--panel-border);
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		border-radius: 30px;
		&:before {
			background: var(--surface-0);
			width: 1.25rem;
			height: 1.25rem;
			left: 0.25rem;
			margin-top: -0.625rem;
			border-radius: 50%;
			transition-duration: 0.2s;
		}
	}
	&:not(.p-disabled) {
		&:hover {
			.p-inputswitch-slider {
				background: #b6bfc8;
			}
		}
	}
}
.p-inputswitch.p-inputswitch-checked {
	.p-inputswitch-slider {
		&:before {
			transform: translateX(1.25rem);
			background: var(--surface-0);
		}
		background: var(--primary-color);
	}
	&:not(.p-disabled) {
		&:hover {
			.p-inputswitch-slider {
				background: var(--button-hover);
			}
		}
	}
}
.p-inputswitch.p-focus {
	.p-inputswitch-slider {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem var(--shadow-color);
	}
}
p-inputswitch.ng-dirty.ng-invalid {
	>.p-inputswitch {
		border-color: var(--invalid-error);
	}
}
.p-inputtext {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-size: 1rem;
	color: var(--text-color);
	background: var(--surface-0);
	padding: 0.5rem 0.5rem;
	border: 1px solid var(--panel-border);
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	appearance: none;
	border-radius: 3px;
	&:enabled {
		&:hover {
			border-color: var(--primary-color);
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: 0 0 0 0.2rem var(--shadow-color);
			border-color: var(--primary-color);
		}
	}
}
.p-inputtext.ng-dirty.ng-invalid {
	border-color: var(--invalid-error);
}
.p-inputtext.p-inputtext-sm {
	font-size: 0.875rem;
	padding: 0.4375rem 0.4375rem;
}
.p-inputtext.p-inputtext-lg {
	font-size: 1.25rem;
	padding: 0.625rem 0.625rem;
}
.p-float-label {
	>label {
		left: 0.5rem;
		color: var(--text-color-secondary);
		transition-duration: 0.2s;
	}
	>.ng-invalid.ng-dirty {
		+ {
			label {
				color: var(--invalid-error);
			}
		}
	}
}
.p-input-icon-left {
	>i {
		&:first-of-type {
			left: 0.5rem;
			color: var(--text-color-secondary);
		}
	}
	>.p-inputtext {
		padding-left: 2rem;
	}
}
.p-input-icon-left.p-float-label {
	>label {
		left: 2rem;
	}
}
.p-input-icon-right {
	>i {
		&:last-of-type {
			right: 0.5rem;
			color: var(--text-color-secondary);
		}
	}
	>.p-inputtext {
		padding-right: 2rem;
	}
}
::-webkit-input-placeholder {
	color: var(--text-color-secondary);
}
:-moz-placeholder {
	color: var(--text-color-secondary);
}
::-moz-placeholder {
	color: var(--text-color-secondary);
}
:-ms-input-placeholder {
	color: var(--text-color-secondary);
}
.p-inputtext-sm {
	.p-inputtext {
		font-size: 0.875rem;
		padding: 0.4375rem 0.4375rem;
	}
}
.p-inputtext-lg {
	.p-inputtext {
		font-size: 1.25rem;
		padding: 0.625rem 0.625rem;
	}
}
.p-listbox {
	background: var(--surface-0);
	color: var(--text-color);
	border: 1px solid var(--panel-border);
	border-radius: 3px;
	.p-listbox-header {
		padding: 0.5rem 1rem;
		border-bottom: 0 none;
		color: var(--text-color);
		background: var(--surface-b);
		margin: 0;
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
		.p-listbox-filter {
			padding-right: 1.5rem;
		}
		.p-listbox-filter-icon {
			right: 0.5rem;
			color: var(--text-color-secondary);
		}
		.p-checkbox {
			margin-right: 0.5rem;
		}
	}
	.p-listbox-list {
		padding: 0.5rem 0;
		.p-listbox-item {
			margin: 0;
			padding: 0.5rem 1rem;
			border: 0 none;
			color: var(--text-color);
			transition: box-shadow 0.2s;
			border-radius: 0;
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: inset 0 0 0 0.15rem var(--shadow-color);
			}
			.p-checkbox {
				margin-right: 0.5rem;
			}
		}
		.p-listbox-item.p-highlight {
			color: var(--text-color);
			background: var(--background-panel);
		}
		.p-listbox-item-group {
			margin: 0;
			padding: 0.75rem 1rem;
			color: var(--text-color);
			background: var(--surface-0);
			font-weight: 600;
		}
	}
	&:not(.p-disabled) {
		.p-listbox-item {
			&:not(.p-highlight) {
				&:not(.p-disabled) {
					&:hover {
						color: var(--text-color);
						background: var(--surface-c);
					}
				}
			}
		}
	}
}
p-listbox.ng-dirty.ng-invalid {
	>.p-listbox {
		border-color: var(--invalid-error);
	}
}
.p-multiselect {
	background: var(--surface-0);
	border: 1px solid var(--panel-border);
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	border-radius: 3px;
	&:not(.p-disabled) {
		&:hover {
			border-color: var(--primary-color);
		}
	}
	&:not(.p-disabled).p-focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem var(--shadow-color);
		border-color: var(--primary-color);
	}
	.p-multiselect-label {
		padding: 0.5rem 0.5rem;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	}
	.p-multiselect-label.p-placeholder {
		color: var(--text-color-secondary);
	}
	.p-multiselect-trigger {
		background: transparent;
		color: var(--text-color-secondary);
		width: 2.357rem;
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
	}
}
.p-multiselect.p-multiselect-chip {
	.p-multiselect-token {
		padding: 0.25rem 0.5rem;
		margin-right: 0.5rem;
		background: var(--background-panel);
		color: var(--text-color);
		border-radius: 3px;
		.p-multiselect-token-icon {
			margin-left: 0.5rem;
		}
	}
}
.p-inputwrapper-filled {
	.p-multiselect.p-multiselect-chip {
		.p-multiselect-label {
			padding: 0.25rem 0.5rem;
		}
	}
}
.p-multiselect-panel {
	background: var(--surface-0);
	color: var(--text-color);
	border: 0 none;
	border-radius: 3px;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
	.p-multiselect-header {
		padding: 0.5rem 1rem;
		border-bottom: 0 none;
		color: var(--text-color);
		background: var(--surface-b);
		margin: 0;
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
		.p-multiselect-filter-container {
			.p-inputtext {
				padding-right: 1.5rem;
			}
			.p-multiselect-filter-icon {
				right: 0.5rem;
				color: var(--text-color-secondary);
			}
		}
		.p-checkbox {
			margin-right: 0.5rem;
		}
		.p-multiselect-close {
			margin-left: 0.5rem;
			width: 2rem;
			height: 2rem;
			color: var(--text-color-secondary);
			border: 0 none;
			background: transparent;
			border-radius: 50%;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			&:enabled {
				&:hover {
					color: var(--text-color);
					border-color: transparent;
					background: var(--surface-c);
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: 0 0 0 0.2rem var(--shadow-color);
			}
		}
	}
	.p-multiselect-items {
		padding: 0.5rem 0;
		.p-multiselect-item {
			margin: 0;
			padding: 0.5rem 1rem;
			border: 0 none;
			color: var(--text-color);
			background: transparent;
			transition: box-shadow 0.2s;
			border-radius: 0;
			&:not(.p-highlight) {
				&:not(.p-disabled) {
					&:hover {
						color: var(--text-color);
						background: var(--surface-c);
					}
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: inset 0 0 0 0.15rem var(--shadow-color);
			}
			.p-checkbox {
				margin-right: 0.5rem;
			}
		}
		.p-multiselect-item.p-highlight {
			color: var(--text-color);
			background: var(--background-panel);
		}
		.p-multiselect-item-group {
			margin: 0;
			padding: 0.75rem 1rem;
			color: var(--text-color);
			background: var(--surface-0);
			font-weight: 600;
		}
		.p-multiselect-empty-message {
			padding: 0.5rem 1rem;
			color: var(--text-color);
			background: transparent;
		}
	}
}
p-multiselect.ng-dirty.ng-invalid {
	>.p-multiselect {
		border-color: var(--invalid-error);
	}
}
.p-password.p-invalid.p-component {
	>.p-inputtext {
		border-color: var(--invalid-error);
	}
}
.p-password-panel {
	padding: 1rem;
	background: var(--surface-0);
	color: var(--text-color);
	border: 0 none;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
	border-radius: 3px;
	.p-password-meter {
		margin-bottom: 0.5rem;
		background: var(--surface-d);
		.p-password-strength.weak {
			background: #D32F2F;
		}
		.p-password-strength.medium {
			background: #FBC02D;
		}
		.p-password-strength.strong {
			background: #689F38;
		}
	}
}
.p-radiobutton {
	width: 20px;
	height: 20px;
	.p-radiobutton-box {
		border: 2px solid var(--panel-border);
		background: var(--surface-0);
		width: 20px;
		height: 20px;
		color: var(--text-color);
		border-radius: 50%;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		&:not(.p-disabled) {
			&:not(.p-highlight) {
				&:hover {
					border-color: var(--primary-color);
				}
			}
		}
		&:not(.p-disabled).p-focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: 0 0 0 0.2rem var(--shadow-color);
			border-color: var(--primary-color);
		}
		.p-radiobutton-icon {
			width: 12px;
			height: 12px;
			transition-duration: 0.2s;
			background-color: var(--primary-color-text);
		}
	}
	.p-radiobutton-box.p-highlight {
		border-color: var(--primary-color);
		background: var(--primary-color);
		&:not(.p-disabled) {
			&:hover {
				border-color: var(--element-hover);
				background: var(--element-hover);
				color: var(--primary-color-text);
			}
		}
	}
}
p-radiobutton.ng-dirty.ng-invalid {
	>.p-radiobutton {
		>.p-radiobutton-box {
			border-color: var(--invalid-error);
		}
	}
}
.p-radiobutton-label {
	margin-left: 0.5rem;
}
.p-rating {
	.p-rating-icon {
		color: var(--text-color);
		margin-left: 0.5rem;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		font-size: 1.143rem;
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: 0 0 0 0.2rem var(--shadow-color);
		}
		&:first-child {
			margin-left: 0;
		}
	}
	.p-rating-icon.p-rating-cancel {
		color: #e74c3c;
	}
	.p-rating-icon.pi-star {
		color: var(--primary-color);
	}
	&:not(.p-disabled) {
		&:not(.p-readonly) {
			.p-rating-icon {
				&:hover {
					color: var(--primary-color);
				}
			}
			.p-rating-icon.p-rating-cancel {
				&:hover {
					color: #c0392b;
				}
			}
		}
	}
}
.p-selectbutton {
	.p-button {
		background: var(--surface-0);
		border: 1px solid var(--panel-border);
		color: var(--text-color);
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		.p-button-icon-left {
			color: var(--text-color-secondary);
		}
		.p-button-icon-right {
			color: var(--text-color-secondary);
		}
		&:not(.p-disabled) {
			&:not(.p-highlight) {
				&:hover {
					background: var(--surface-c);
					border-color: var(--panel-border);
					color: var(--text-color);
					.p-button-icon-left {
						color: var(--text-color-secondary);
					}
					.p-button-icon-right {
						color: var(--text-color-secondary);
					}
				}
			}
		}
	}
	.p-button.p-highlight {
		background: var(--primary-color);
		border-color: var(--primary-color);
		color: var(--primary-color-text);
		.p-button-icon-left {
			color: var(--primary-color-text);
		}
		.p-button-icon-right {
			color: var(--primary-color-text);
		}
		&:hover {
			background: var(--button-hover);
			border-color: var(--button-hover);
			color: var(--primary-color-text);
			.p-button-icon-left {
				color: var(--primary-color-text);
			}
			.p-button-icon-right {
				color: var(--primary-color-text);
			}
		}
	}
}
p-selectbutton.ng-dirty.ng-invalid {
	>.p-selectbutton {
		>.p-button {
			border-color: var(--invalid-error);
		}
	}
}
.p-slider {
	background: var(--surface-d);
	border: 0 none;
	border-radius: 3px;
	.p-slider-handle {
		height: 1.143rem;
		width: 1.143rem;
		background: var(--surface-0);
		border: 2px solid var(--primary-color);
		border-radius: 50%;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: 0 0 0 0.2rem var(--shadow-color);
		}
	}
	.p-slider-range {
		background: var(--primary-color);
	}
	&:not(.p-disabled) {
		.p-slider-handle {
			&:hover {
				background: var(--primary-color);
				border-color: var(--primary-color);
			}
		}
	}
}
.p-slider.p-slider-horizontal {
	height: 0.286rem;
	.p-slider-handle {
		margin-top: -0.5715rem;
		margin-left: -0.5715rem;
	}
}
.p-slider.p-slider-vertical {
	width: 0.286rem;
	.p-slider-handle {
		margin-left: -0.5715rem;
		margin-bottom: -0.5715rem;
	}
}
.p-slider.p-slider-animate.p-slider-horizontal {
	.p-slider-handle {
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, left 0.2s;
	}
	.p-slider-range {
		transition: width 0.2s;
	}
}
.p-slider.p-slider-animate.p-slider-vertical {
	.p-slider-handle {
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, bottom 0.2s;
	}
	.p-slider-range {
		transition: height 0.2s;
	}
}
.p-togglebutton.p-button {
	background: var(--surface-0);
	border: 1px solid var(--panel-border);
	color: var(--text-color);
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	.p-button-icon-left {
		color: var(--text-color-secondary);
	}
	.p-button-icon-right {
		color: var(--text-color-secondary);
	}
	&:not(.p-disabled) {
		&:not(.p-highlight) {
			&:hover {
				background: var(--surface-c);
				border-color: var(--panel-border);
				color: var(--text-color);
				.p-button-icon-left {
					color: var(--text-color-secondary);
				}
				.p-button-icon-right {
					color: var(--text-color-secondary);
				}
			}
		}
	}
}
.p-togglebutton.p-button.p-highlight {
	background: var(--primary-color);
	border-color: var(--primary-color);
	color: var(--primary-color-text);
	.p-button-icon-left {
		color: var(--primary-color-text);
	}
	.p-button-icon-right {
		color: var(--primary-color-text);
	}
	&:hover {
		background: var(--button-hover);
		border-color: var(--button-hover);
		color: var(--primary-color-text);
		.p-button-icon-left {
			color: var(--primary-color-text);
		}
		.p-button-icon-right {
			color: var(--primary-color-text);
		}
	}
}
p-togglebutton.ng-dirty.ng-invalid {
	>.p-togglebutton.p-button {
		border-color: var(--invalid-error);
	}
}
.p-button {
	color: var(--primary-color-text);
	background: var(--primary-color);
	border: 1px solid var(--primary-color);
	padding: 0.5rem 1rem;
	font-size: 1rem;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	border-radius: 3px;
	&:enabled {
		&:hover {
			background: var(--button-hover);
			color: var(--primary-color-text);
			border-color: var(--button-hover);
		}
		&:active {
			background: var(--element-hover);
			color: var(--primary-color-text);
			border-color: var(--element-hover);
		}
	}
	&:focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem var(--shadow-color);
	}
	.p-button-icon-left {
		margin-right: 0.5rem;
	}
	.p-button-icon-right {
		margin-left: 0.5rem;
	}
	.p-button-icon-bottom {
		margin-top: 0.5rem;
	}
	.p-button-icon-top {
		margin-bottom: 0.5rem;
	}
	.p-badge {
		margin-left: 0.5rem;
		min-width: 1rem;
		height: 1rem;
		line-height: 1rem;
		color: var(--primary-color);
		background-color: var(--primary-color-text);
	}
}
.p-button.p-button-outlined {
	background-color: transparent;
	color: var(--primary-color);
	border: 1px solid;
	&:enabled {
		&:hover {
			background: rgba(255, 193, 7, 0.04);
			color: var(--primary-color);
			border: 1px solid;
		}
		&:active {
			background: rgba(255, 193, 7, 0.16);
			color: var(--primary-color);
			border: 1px solid;
		}
	}
}
.p-button.p-button-outlined.p-button-plain {
	color: var(--text-color-secondary);
	border-color: var(--text-color-secondary);
	&:enabled {
		&:hover {
			background: var(--surface-c);
			color: var(--text-color-secondary);
		}
		&:active {
			background: var(--surface-d);
			color: var(--text-color-secondary);
		}
	}
}
.p-button.p-button-text {
	background-color: transparent;
	color: var(--primary-color);
	border-color: transparent;
	&:enabled {
		&:hover {
			background: rgba(255, 193, 7, 0.04);
			color: var(--primary-color);
			border-color: transparent;
		}
		&:active {
			background: rgba(255, 193, 7, 0.16);
			color: var(--primary-color);
			border-color: transparent;
		}
	}
}
.p-button.p-button-text.p-button-plain {
	color: var(--text-color-secondary);
	&:enabled {
		&:hover {
			background: var(--surface-c);
			color: var(--text-color-secondary);
		}
		&:active {
			background: var(--surface-d);
			color: var(--text-color-secondary);
		}
	}
}
.p-button.p-button-raised {
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.p-button.p-button-rounded {
	border-radius: 2rem;
}
.p-button.p-button-icon-only {
	width: 2.357rem;
	padding: 0.5rem 0;
	.p-button-icon-left {
		margin: 0;
	}
	.p-button-icon-right {
		margin: 0;
	}
}
.p-button.p-button-icon-only.p-button-rounded {
	border-radius: 50%;
	height: 2.357rem;
}
.p-button.p-button-sm {
	font-size: 0.875rem;
	padding: 0.4375rem 0.875rem;
	.p-button-icon {
		font-size: 0.875rem;
	}
}
.p-button.p-button-lg {
	font-size: 1.25rem;
	padding: 0.625rem 1.25rem;
	.p-button-icon {
		font-size: 1.25rem;
	}
}
.p-button.p-button-secondary {
	background: #607D8B;
  color: #ffffff;
  border: 1px solid #607D8B;
	&:enabled {
		&:hover {
			background: #56717d;
			color: var(--surface-0);
			border-color: #56717d;
		}
		&:focus {
			box-shadow: 0 0 0 0.2rem #beccd2;
		}
		&:active {
			background: #4d646f;
			color: var(--surface-0);
			border-color: #4d646f;
		}
	}
}
.p-buttonset.p-button-secondary {
	>.p-button {
		color: var(--surface-0);
		background: var(--button-secondary);
		border: 1px solid var(--button-secondary);
		&:enabled {
			&:hover {
				background: #56717d;
				color: var(--surface-0);
				border-color: #56717d;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem #beccd2;
			}
			&:active {
				background: #4d646f;
				color: var(--surface-0);
				border-color: #4d646f;
			}
		}
	}
	>.p-button.p-button-outlined {
		background-color: transparent;
		color: var(--button-secondary);
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(96, 125, 139, 0.04);
				color: var(--button-secondary);
				border: 1px solid;
			}
			&:active {
				background: rgba(96, 125, 139, 0.16);
				color: var(--button-secondary);
				border: 1px solid;
			}
		}
	}
	>.p-button.p-button-text {
		background-color: transparent;
		color: var(--button-secondary);
		border-color: transparent;
		&:enabled {
			&:hover {
				background: rgba(96, 125, 139, 0.04);
				border-color: transparent;
				color: var(--button-secondary);
			}
			&:active {
				background: rgba(96, 125, 139, 0.16);
				border-color: transparent;
				color: var(--button-secondary);
			}
		}
	}
}
.p-splitbutton.p-button-secondary {
	>.p-button {
		color: var(--surface-0);
		background: var(--button-secondary);
		border: 1px solid var(--button-secondary);
		&:enabled {
			&:hover {
				background: #56717d;
				color: var(--surface-0);
				border-color: #56717d;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem #beccd2;
			}
			&:active {
				background: #4d646f;
				color: var(--surface-0);
				border-color: #4d646f;
			}
		}
	}
	>.p-button.p-button-outlined {
		background-color: transparent;
		color: var(--button-secondary);
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(96, 125, 139, 0.04);
				color: var(--button-secondary);
				border: 1px solid;
			}
			&:active {
				background: rgba(96, 125, 139, 0.16);
				color: var(--button-secondary);
				border: 1px solid;
			}
		}
	}
	>.p-button.p-button-text {
		background-color: transparent;
		color: var(--button-secondary);
		border-color: transparent;
		&:enabled {
			&:hover {
				background: rgba(96, 125, 139, 0.04);
				border-color: transparent;
				color: var(--button-secondary);
			}
			&:active {
				background: rgba(96, 125, 139, 0.16);
				border-color: transparent;
				color: var(--button-secondary);
			}
		}
	}
}
.p-button.p-button-secondary.p-button-outlined {
	background-color: transparent;
	color: var(--button-secondary);
	border: 1px solid;
	&:enabled {
		&:hover {
			background: rgba(96, 125, 139, 0.04);
			color: var(--button-secondary);
			border: 1px solid;
		}
		&:active {
			background: rgba(96, 125, 139, 0.16);
			color: var(--button-secondary);
			border: 1px solid;
		}
	}
}
.p-button.p-button-secondary.p-button-text {
	background-color: transparent;
	color: var(--button-secondary);
	border-color: transparent;
	&:enabled {
		&:hover {
			background: rgba(96, 125, 139, 0.04);
			border-color: transparent;
			color: var(--button-secondary);
		}
		&:active {
			background: rgba(96, 125, 139, 0.16);
			border-color: transparent;
			color: var(--button-secondary);
		}
	}
}
.p-button.p-button-info {
	color: var(--surface-0);
	background: #0288D1;
	border: 1px solid #0288D1;
	&:enabled {
		&:hover {
			background: #027abc;
			color: var(--surface-0);
			border-color: #027abc;
		}
		&:focus {
			box-shadow: 0 0 0 0.2rem #89d4fe;
		}
		&:active {
			background: #026da7;
			color: var(--surface-0);
			border-color: #026da7;
		}
	}
}
.p-buttonset.p-button-info {
	>.p-button {
		color: var(--surface-0);
		background: #0288D1;
		border: 1px solid #0288D1;
		&:enabled {
			&:hover {
				background: #027abc;
				color: var(--surface-0);
				border-color: #027abc;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem #89d4fe;
			}
			&:active {
				background: #026da7;
				color: var(--surface-0);
				border-color: #026da7;
			}
		}
	}
	>.p-button.p-button-outlined {
		background-color: transparent;
		color: #0288D1;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(2, 136, 209, 0.04);
				color: #0288D1;
				border: 1px solid;
			}
			&:active {
				background: rgba(2, 136, 209, 0.16);
				color: #0288D1;
				border: 1px solid;
			}
		}
	}
	>.p-button.p-button-text {
		background-color: transparent;
		color: #0288D1;
		border-color: transparent;
		&:enabled {
			&:hover {
				background: rgba(2, 136, 209, 0.04);
				border-color: transparent;
				color: #0288D1;
			}
			&:active {
				background: rgba(2, 136, 209, 0.16);
				border-color: transparent;
				color: #0288D1;
			}
		}
	}
}
.p-splitbutton.p-button-info {
	>.p-button {
		color: var(--surface-0);
		background: #0288D1;
		border: 1px solid #0288D1;
		&:enabled {
			&:hover {
				background: #027abc;
				color: var(--surface-0);
				border-color: #027abc;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem #89d4fe;
			}
			&:active {
				background: #026da7;
				color: var(--surface-0);
				border-color: #026da7;
			}
		}
	}
	>.p-button.p-button-outlined {
		background-color: transparent;
		color: #0288D1;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(2, 136, 209, 0.04);
				color: #0288D1;
				border: 1px solid;
			}
			&:active {
				background: rgba(2, 136, 209, 0.16);
				color: #0288D1;
				border: 1px solid;
			}
		}
	}
	>.p-button.p-button-text {
		background-color: transparent;
		color: #0288D1;
		border-color: transparent;
		&:enabled {
			&:hover {
				background: rgba(2, 136, 209, 0.04);
				border-color: transparent;
				color: #0288D1;
			}
			&:active {
				background: rgba(2, 136, 209, 0.16);
				border-color: transparent;
				color: #0288D1;
			}
		}
	}
}
.p-button.p-button-info.p-button-outlined {
	background-color: transparent;
	color: #0288D1;
	border: 1px solid;
	&:enabled {
		&:hover {
			background: rgba(2, 136, 209, 0.04);
			color: #0288D1;
			border: 1px solid;
		}
		&:active {
			background: rgba(2, 136, 209, 0.16);
			color: #0288D1;
			border: 1px solid;
		}
	}
}
.p-button.p-button-info.p-button-text {
	background-color: transparent;
	color: #0288D1;
	border-color: transparent;
	&:enabled {
		&:hover {
			background: rgba(2, 136, 209, 0.04);
			border-color: transparent;
			color: #0288D1;
		}
		&:active {
			background: rgba(2, 136, 209, 0.16);
			border-color: transparent;
			color: #0288D1;
		}
	}
}
.p-button.p-button-success {
	color: var(--surface-0);
	background: #689F38;
	border: 1px solid #689F38;
	&:enabled {
		&:hover {
			background: #5e8f32;
			color: var(--surface-0);
			border-color: #5e8f32;
		}
		&:focus {
			box-shadow: 0 0 0 0.2rem #c2e0a8;
		}
		&:active {
			background: #537f2d;
			color: var(--surface-0);
			border-color: #537f2d;
		}
	}
}
.p-buttonset.p-button-success {
	>.p-button {
		color: var(--surface-0);
		background: #689F38;
		border: 1px solid #689F38;
		&:enabled {
			&:hover {
				background: #5e8f32;
				color: var(--surface-0);
				border-color: #5e8f32;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem #c2e0a8;
			}
			&:active {
				background: #537f2d;
				color: var(--surface-0);
				border-color: #537f2d;
			}
		}
	}
	>.p-button.p-button-outlined {
		background-color: transparent;
		color: #689F38;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(104, 159, 56, 0.04);
				color: #689F38;
				border: 1px solid;
			}
			&:active {
				background: rgba(104, 159, 56, 0.16);
				color: #689F38;
				border: 1px solid;
			}
		}
	}
	>.p-button.p-button-text {
		background-color: transparent;
		color: #689F38;
		border-color: transparent;
		&:enabled {
			&:hover {
				background: rgba(104, 159, 56, 0.04);
				border-color: transparent;
				color: #689F38;
			}
			&:active {
				background: rgba(104, 159, 56, 0.16);
				border-color: transparent;
				color: #689F38;
			}
		}
	}
}
.p-splitbutton.p-button-success {
	>.p-button {
		color: var(--surface-0);
		background: #689F38;
		border: 1px solid #689F38;
		&:enabled {
			&:hover {
				background: #5e8f32;
				color: var(--surface-0);
				border-color: #5e8f32;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem #c2e0a8;
			}
			&:active {
				background: #537f2d;
				color: var(--surface-0);
				border-color: #537f2d;
			}
		}
	}
	>.p-button.p-button-outlined {
		background-color: transparent;
		color: #689F38;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(104, 159, 56, 0.04);
				color: #689F38;
				border: 1px solid;
			}
			&:active {
				background: rgba(104, 159, 56, 0.16);
				color: #689F38;
				border: 1px solid;
			}
		}
	}
	>.p-button.p-button-text {
		background-color: transparent;
		color: #689F38;
		border-color: transparent;
		&:enabled {
			&:hover {
				background: rgba(104, 159, 56, 0.04);
				border-color: transparent;
				color: #689F38;
			}
			&:active {
				background: rgba(104, 159, 56, 0.16);
				border-color: transparent;
				color: #689F38;
			}
		}
	}
}
.p-button.p-button-success.p-button-outlined {
	background-color: transparent;
	color: #689F38;
	border: 1px solid;
	&:enabled {
		&:hover {
			background: rgba(104, 159, 56, 0.04);
			color: #689F38;
			border: 1px solid;
		}
		&:active {
			background: rgba(104, 159, 56, 0.16);
			color: #689F38;
			border: 1px solid;
		}
	}
}
.p-button.p-button-success.p-button-text {
	background-color: transparent;
	color: #689F38;
	border-color: transparent;
	&:enabled {
		&:hover {
			background: rgba(104, 159, 56, 0.04);
			border-color: transparent;
			color: #689F38;
		}
		&:active {
			background: rgba(104, 159, 56, 0.16);
			border-color: transparent;
			color: #689F38;
		}
	}
}
.p-button.p-button-warning {
	color: var(--primary-color-text);
	background: #FBC02D;
	border: 1px solid #FBC02D;
	&:enabled {
		&:hover {
			background: #fab710;
			color: var(--primary-color-text);
			border-color: #fab710;
		}
		&:focus {
			box-shadow: 0 0 0 0.2rem #fde6ab;
		}
		&:active {
			background: #e8a704;
			color: var(--primary-color-text);
			border-color: #e8a704;
		}
	}
}
.p-buttonset.p-button-warning {
	>.p-button {
		color: var(--primary-color-text);
		background: #FBC02D;
		border: 1px solid #FBC02D;
		&:enabled {
			&:hover {
				background: #fab710;
				color: var(--primary-color-text);
				border-color: #fab710;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem #fde6ab;
			}
			&:active {
				background: #e8a704;
				color: var(--primary-color-text);
				border-color: #e8a704;
			}
		}
	}
	>.p-button.p-button-outlined {
		background-color: transparent;
		color: #FBC02D;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(251, 192, 45, 0.04);
				color: #FBC02D;
				border: 1px solid;
			}
			&:active {
				background: rgba(251, 192, 45, 0.16);
				color: #FBC02D;
				border: 1px solid;
			}
		}
	}
	>.p-button.p-button-text {
		background-color: transparent;
		color: #FBC02D;
		border-color: transparent;
		&:enabled {
			&:hover {
				background: rgba(251, 192, 45, 0.04);
				border-color: transparent;
				color: #FBC02D;
			}
			&:active {
				background: rgba(251, 192, 45, 0.16);
				border-color: transparent;
				color: #FBC02D;
			}
		}
	}
}
.p-splitbutton.p-button-warning {
	>.p-button {
		color: var(--primary-color-text);
		background: #FBC02D;
		border: 1px solid #FBC02D;
		&:enabled {
			&:hover {
				background: #fab710;
				color: var(--primary-color-text);
				border-color: #fab710;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem #fde6ab;
			}
			&:active {
				background: #e8a704;
				color: var(--primary-color-text);
				border-color: #e8a704;
			}
		}
	}
	>.p-button.p-button-outlined {
		background-color: transparent;
		color: #FBC02D;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(251, 192, 45, 0.04);
				color: #FBC02D;
				border: 1px solid;
			}
			&:active {
				background: rgba(251, 192, 45, 0.16);
				color: #FBC02D;
				border: 1px solid;
			}
		}
	}
	>.p-button.p-button-text {
		background-color: transparent;
		color: #FBC02D;
		border-color: transparent;
		&:enabled {
			&:hover {
				background: rgba(251, 192, 45, 0.04);
				border-color: transparent;
				color: #FBC02D;
			}
			&:active {
				background: rgba(251, 192, 45, 0.16);
				border-color: transparent;
				color: #FBC02D;
			}
		}
	}
}
.p-button.p-button-warning.p-button-outlined {
	background-color: transparent;
	color: #FBC02D;
	border: 1px solid;
	&:enabled {
		&:hover {
			background: rgba(251, 192, 45, 0.04);
			color: #FBC02D;
			border: 1px solid;
		}
		&:active {
			background: rgba(251, 192, 45, 0.16);
			color: #FBC02D;
			border: 1px solid;
		}
	}
}
.p-button.p-button-warning.p-button-text {
	background-color: transparent;
	color: #FBC02D;
	border-color: transparent;
	&:enabled {
		&:hover {
			background: rgba(251, 192, 45, 0.04);
			border-color: transparent;
			color: #FBC02D;
		}
		&:active {
			background: rgba(251, 192, 45, 0.16);
			border-color: transparent;
			color: #FBC02D;
		}
	}
}
.p-button.p-button-help {
	color: var(--surface-0);
	background: #9C27B0;
	border: 1px solid #9C27B0;
	&:enabled {
		&:hover {
			background: #8c239e;
			color: var(--surface-0);
			border-color: #8c239e;
		}
		&:focus {
			box-shadow: 0 0 0 0.2rem #df9eea;
		}
		&:active {
			background: #7d1f8d;
			color: var(--surface-0);
			border-color: #7d1f8d;
		}
	}
}
.p-buttonset.p-button-help {
	>.p-button {
		color: var(--surface-0);
		background: #9C27B0;
		border: 1px solid #9C27B0;
		&:enabled {
			&:hover {
				background: #8c239e;
				color: var(--surface-0);
				border-color: #8c239e;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem #df9eea;
			}
			&:active {
				background: #7d1f8d;
				color: var(--surface-0);
				border-color: #7d1f8d;
			}
		}
	}
	>.p-button.p-button-outlined {
		background-color: transparent;
		color: #9C27B0;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(156, 39, 176, 0.04);
				color: #9C27B0;
				border: 1px solid;
			}
			&:active {
				background: rgba(156, 39, 176, 0.16);
				color: #9C27B0;
				border: 1px solid;
			}
		}
	}
	>.p-button.p-button-text {
		background-color: transparent;
		color: #9C27B0;
		border-color: transparent;
		&:enabled {
			&:hover {
				background: rgba(156, 39, 176, 0.04);
				border-color: transparent;
				color: #9C27B0;
			}
			&:active {
				background: rgba(156, 39, 176, 0.16);
				border-color: transparent;
				color: #9C27B0;
			}
		}
	}
}
.p-splitbutton.p-button-help {
	>.p-button {
		color: var(--surface-0);
		background: #9C27B0;
		border: 1px solid #9C27B0;
		&:enabled {
			&:hover {
				background: #8c239e;
				color: var(--surface-0);
				border-color: #8c239e;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem #df9eea;
			}
			&:active {
				background: #7d1f8d;
				color: var(--surface-0);
				border-color: #7d1f8d;
			}
		}
	}
	>.p-button.p-button-outlined {
		background-color: transparent;
		color: #9C27B0;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(156, 39, 176, 0.04);
				color: #9C27B0;
				border: 1px solid;
			}
			&:active {
				background: rgba(156, 39, 176, 0.16);
				color: #9C27B0;
				border: 1px solid;
			}
		}
	}
	>.p-button.p-button-text {
		background-color: transparent;
		color: #9C27B0;
		border-color: transparent;
		&:enabled {
			&:hover {
				background: rgba(156, 39, 176, 0.04);
				border-color: transparent;
				color: #9C27B0;
			}
			&:active {
				background: rgba(156, 39, 176, 0.16);
				border-color: transparent;
				color: #9C27B0;
			}
		}
	}
}
.p-button.p-button-help.p-button-outlined {
	background-color: transparent;
	color: #9C27B0;
	border: 1px solid;
	&:enabled {
		&:hover {
			background: rgba(156, 39, 176, 0.04);
			color: #9C27B0;
			border: 1px solid;
		}
		&:active {
			background: rgba(156, 39, 176, 0.16);
			color: #9C27B0;
			border: 1px solid;
		}
	}
}
.p-button.p-button-help.p-button-text {
	background-color: transparent;
	color: #9C27B0;
	border-color: transparent;
	&:enabled {
		&:hover {
			background: rgba(156, 39, 176, 0.04);
			border-color: transparent;
			color: #9C27B0;
		}
		&:active {
			background: rgba(156, 39, 176, 0.16);
			border-color: transparent;
			color: #9C27B0;
		}
	}
}
.p-button.p-button-danger {
	color: var(--surface-0);
	background: #D32F2F;
	border: 1px solid #D32F2F;
	&:enabled {
		&:hover {
			background: #c02929;
			color: var(--surface-0);
			border-color: #c02929;
		}
		&:focus {
			box-shadow: 0 0 0 0.2rem #edacac;
		}
		&:active {
			background: #aa2424;
			color: var(--surface-0);
			border-color: #aa2424;
		}
	}
}
.p-buttonset.p-button-danger {
	>.p-button {
		color: var(--surface-0);
		background: #D32F2F;
		border: 1px solid #D32F2F;
		&:enabled {
			&:hover {
				background: #c02929;
				color: var(--surface-0);
				border-color: #c02929;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem #edacac;
			}
			&:active {
				background: #aa2424;
				color: var(--surface-0);
				border-color: #aa2424;
			}
		}
	}
	>.p-button.p-button-outlined {
		background-color: transparent;
		color: #D32F2F;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(211, 47, 47, 0.04);
				color: #D32F2F;
				border: 1px solid;
			}
			&:active {
				background: rgba(211, 47, 47, 0.16);
				color: #D32F2F;
				border: 1px solid;
			}
		}
	}
	>.p-button.p-button-text {
		background-color: transparent;
		color: #D32F2F;
		border-color: transparent;
		&:enabled {
			&:hover {
				background: rgba(211, 47, 47, 0.04);
				border-color: transparent;
				color: #D32F2F;
			}
			&:active {
				background: rgba(211, 47, 47, 0.16);
				border-color: transparent;
				color: #D32F2F;
			}
		}
	}
}
.p-splitbutton.p-button-danger {
	>.p-button {
		color: var(--surface-0);
		background: #D32F2F;
		border: 1px solid #D32F2F;
		&:enabled {
			&:hover {
				background: #c02929;
				color: var(--surface-0);
				border-color: #c02929;
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem #edacac;
			}
			&:active {
				background: #aa2424;
				color: var(--surface-0);
				border-color: #aa2424;
			}
		}
	}
	>.p-button.p-button-outlined {
		background-color: transparent;
		color: #D32F2F;
		border: 1px solid;
		&:enabled {
			&:hover {
				background: rgba(211, 47, 47, 0.04);
				color: #D32F2F;
				border: 1px solid;
			}
			&:active {
				background: rgba(211, 47, 47, 0.16);
				color: #D32F2F;
				border: 1px solid;
			}
		}
	}
	>.p-button.p-button-text {
		background-color: transparent;
		color: #D32F2F;
		border-color: transparent;
		&:enabled {
			&:hover {
				background: rgba(211, 47, 47, 0.04);
				border-color: transparent;
				color: #D32F2F;
			}
			&:active {
				background: rgba(211, 47, 47, 0.16);
				border-color: transparent;
				color: #D32F2F;
			}
		}
	}
}
.p-button.p-button-danger.p-button-outlined {
	background-color: transparent;
	color: #D32F2F;
	border: 1px solid;
	&:enabled {
		&:hover {
			background: rgba(211, 47, 47, 0.04);
			color: #D32F2F;
			border: 1px solid;
		}
		&:active {
			background: rgba(211, 47, 47, 0.16);
			color: #D32F2F;
			border: 1px solid;
		}
	}
}
.p-button.p-button-danger.p-button-text {
	background-color: transparent;
	color: #D32F2F;
	border-color: transparent;
	&:enabled {
		&:hover {
			background: rgba(211, 47, 47, 0.04);
			border-color: transparent;
			color: #D32F2F;
		}
		&:active {
			background: rgba(211, 47, 47, 0.16);
			border-color: transparent;
			color: #D32F2F;
		}
	}
}
.p-button.p-button-link {
	color: var(--element-hover);
	background: transparent;
	border: transparent;
	&:enabled {
		&:hover {
			background: transparent;
			color: var(--element-hover);
			border-color: transparent;
			.p-button-label {
				text-decoration: underline;
			}
		}
		&:focus {
			background: transparent;
			box-shadow: 0 0 0 0.2rem var(--shadow-color);
			border-color: transparent;
		}
		&:active {
			background: transparent;
			color: var(--element-hover);
			border-color: transparent;
		}
	}
}
.p-carousel {
	.p-carousel-content {
		.p-carousel-prev {
			width: 2rem;
			height: 2rem;
			color: var(--text-color-secondary);
			border: 0 none;
			background: transparent;
			border-radius: 50%;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			margin: 0.5rem;
			&:enabled {
				&:hover {
					color: var(--text-color);
					border-color: transparent;
					background: var(--surface-c);
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: 0 0 0 0.2rem var(--shadow-color);
			}
		}
		.p-carousel-next {
			width: 2rem;
			height: 2rem;
			color: var(--text-color-secondary);
			border: 0 none;
			background: transparent;
			border-radius: 50%;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			margin: 0.5rem;
			&:enabled {
				&:hover {
					color: var(--text-color);
					border-color: transparent;
					background: var(--surface-c);
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: 0 0 0 0.2rem var(--shadow-color);
			}
		}
	}
	.p-carousel-indicators {
		padding: 1rem;
		.p-carousel-indicator {
			margin-right: 0.5rem;
			margin-bottom: 0.5rem;
			button {
				background-color: var(--surface-c);
				width: 2rem;
				height: 0.5rem;
				transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
				border-radius: 0;
				&:hover {
					background: var(--surface-d);
				}
			}
		}
		.p-carousel-indicator.p-highlight {
			button {
				background: var(--background-panel);
				color: var(--text-color);
				background-color: var(--primary-color);
			}
		}
	}
}
.p-datatable {
	.p-paginator-top {
		border-width: 0 0 1px 0;
		border-radius: 0;
	}
	.p-paginator-bottom {
		border-width: 0 0 1px 0;
		border-radius: 0;
	}
	.p-datatable-header {
		background: var(--surface-b);
		color: var(--text-color);
		border: 1px solid var(--surface-c);
		border-width: 1px 0 1px 0;
		padding: 1rem 1rem;
		font-weight: 600;
	}
	.p-datatable-footer {
		background: var(--surface-b);
		color: var(--text-color);
		border: 1px solid var(--surface-c);
		border-width: 0 0 1px 0;
		padding: 1rem 1rem;
		font-weight: 600;
	}
	.p-datatable-thead {
		>tr {
			>th {
				text-align: left;
				padding: 1rem 1rem;
				border: 1px solid var(--surface-c);
				border-width: 0 0 1px 0;
				font-weight: 600;
				color: var(--text-color);
				background: var(--surface-b);
				transition: box-shadow 0.2s;
			}
		}
	}
	.p-datatable-tfoot {
		>tr {
			>td {
				text-align: left;
				padding: 1rem 1rem;
				border: 1px solid var(--surface-c);
				border-width: 0 0 1px 0;
				font-weight: 600;
				color: var(--text-color);
				background: var(--surface-b);
			}
		}
	}
	.p-sortable-column {
		.p-sortable-column-icon {
			color: var(--text-color-secondary);
			margin-left: 0.5rem;
		}
		.p-sortable-column-badge {
			border-radius: 50%;
			height: 1.143rem;
			min-width: 1.143rem;
			line-height: 1.143rem;
			color: var(--text-color);
			background: var(--background-panel);
			margin-left: 0.5rem;
		}
		&:not(.p-highlight) {
			&:hover {
				background: var(--surface-c);
				color: var(--text-color);
				.p-sortable-column-icon {
					color: var(--text-color-secondary);
				}
			}
		}
		&:focus {
			box-shadow: inset 0 0 0 0.15rem var(--shadow-color);
			outline: 0 none;
		}
	}
	.p-sortable-column.p-highlight {
		background: var(--surface-b);
		color: var(--primary-color);
		.p-sortable-column-icon {
			color: var(--primary-color);
		}
		&:hover {
			background: var(--surface-c);
			color: var(--primary-color);
			.p-sortable-column-icon {
				color: var(--primary-color);
			}
		}
	}
	.p-datatable-tbody {
		>tr {
			background: var(--surface-0);
			color: var(--text-color);
			transition: box-shadow 0.2s;
			outline-color: var(--shadow-color);
			>td {
				text-align: left;
				border: 1px solid var(--surface-c);
				border-width: 0 0 1px 0;
				padding: 1rem 1rem;
				.p-row-toggler {
					width: 2rem;
					height: 2rem;
					color: var(--text-color-secondary);
					border: 0 none;
					background: transparent;
					border-radius: 50%;
					transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
					&:enabled {
						&:hover {
							color: var(--text-color);
							border-color: transparent;
							background: var(--surface-c);
						}
					}
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						box-shadow: 0 0 0 0.2rem var(--shadow-color);
					}
				}
				.p-row-editor-init {
					width: 2rem;
					height: 2rem;
					color: var(--text-color-secondary);
					border: 0 none;
					background: transparent;
					border-radius: 50%;
					transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
					&:enabled {
						&:hover {
							color: var(--text-color);
							border-color: transparent;
							background: var(--surface-c);
						}
					}
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						box-shadow: 0 0 0 0.2rem var(--shadow-color);
					}
				}
				.p-row-editor-save {
					width: 2rem;
					height: 2rem;
					color: var(--text-color-secondary);
					border: 0 none;
					background: transparent;
					border-radius: 50%;
					transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
					margin-right: 0.5rem;
					&:enabled {
						&:hover {
							color: var(--text-color);
							border-color: transparent;
							background: var(--surface-c);
						}
					}
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						box-shadow: 0 0 0 0.2rem var(--shadow-color);
					}
				}
				.p-row-editor-cancel {
					width: 2rem;
					height: 2rem;
					color: var(--text-color-secondary);
					border: 0 none;
					background: transparent;
					border-radius: 50%;
					transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
					&:enabled {
						&:hover {
							color: var(--text-color);
							border-color: transparent;
							background: var(--surface-c);
						}
					}
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						box-shadow: 0 0 0 0.2rem var(--shadow-color);
					}
				}
			}
		}
		>tr.p-highlight {
			background: var(--background-panel);
			color: var(--text-color);
		}
		>tr.p-datatable-dragpoint-top {
			>td {
				box-shadow: inset 0 2px 0 0 var(--background-panel);
			}
		}
		>tr.p-datatable-dragpoint-bottom {
			>td {
				box-shadow: inset 0 -2px 0 0 var(--background-panel);
			}
		}
	}
	.p-column-resizer-helper {
		background: var(--primary-color);
	}
	.p-datatable-scrollable-header {
		background: var(--surface-b);
	}
	.p-datatable-scrollable-footer {
		background: var(--surface-b);
	}
	.p-datatable-loading-icon {
		font-size: 2rem;
	}
}
.p-datatable.p-datatable-hoverable-rows {
	.p-datatable-tbody {
		>tr {
			&:not(.p-highlight) {
				&:hover {
					background: var(--surface-c);
					color: var(--text-color);
				}
			}
		}
	}
}
.p-datatable.p-datatable-gridlines {
	.p-datatable-header {
		border-width: 1px 1px 0 1px;
	}
	.p-datatable-footer {
		border-width: 0 1px 1px 1px;
	}
	.p-paginator-top {
		border-width: 0 1px 0 1px;
	}
	.p-paginator-bottom {
		border-width: 0 1px 1px 1px;
	}
	.p-datatable-thead {
		>tr {
			>th {
				border-width: 1px 1px 1px 1px;
			}
		}
	}
	.p-datatable-tbody {
		>tr {
			>td {
				border-width: 1px;
			}
		}
	}
	.p-datatable-tfoot {
		>tr {
			>td {
				border-width: 1px;
			}
		}
	}
}
.p-datatable.p-datatable-striped {
	.p-datatable-tbody {
		>tr {
			&:nth-child(even) {
				background: #fcfcfc;
			}
			&:nth-child(even).p-highlight {
				background: var(--background-panel);
				color: var(--text-color);
				.p-row-toggler {
					color: var(--text-color);
					&:hover {
						color: var(--text-color);
					}
				}
			}
		}
	}
}
.p-datatable.p-datatable-sm {
	.p-datatable-header {
		padding: 0.5rem 0.5rem;
	}
	.p-datatable-thead {
		>tr {
			>th {
				padding: 0.5rem 0.5rem;
			}
		}
	}
	.p-datatable-tbody {
		>tr {
			>td {
				padding: 0.5rem 0.5rem;
			}
		}
	}
	.p-datatable-tfoot {
		>tr {
			>td {
				padding: 0.5rem 0.5rem;
			}
		}
	}
	.p-datatable-footer {
		padding: 0.5rem 0.5rem;
	}
}
.p-datatable.p-datatable-lg {
	.p-datatable-header {
		padding: 1.25rem 1.25rem;
	}
	.p-datatable-thead {
		>tr {
			>th {
				padding: 1.25rem 1.25rem;
			}
		}
	}
	.p-datatable-tbody {
		>tr {
			>td {
				padding: 1.25rem 1.25rem;
			}
		}
	}
	.p-datatable-tfoot {
		>tr {
			>td {
				padding: 1.25rem 1.25rem;
			}
		}
	}
	.p-datatable-footer {
		padding: 1.25rem 1.25rem;
	}
}
.p-dataview {
	.p-paginator-top {
		border-width: 0 0 1px 0;
		border-radius: 0;
	}
	.p-paginator-bottom {
		border-width: 0 0 1px 0;
		border-radius: 0;
	}
	.p-dataview-header {
		background: var(--surface-b);
		color: var(--text-color);
		border: 1px solid var(--surface-c);
		border-width: 1px 0 1px 0;
		padding: 1rem 1rem;
		font-weight: 600;
	}
	.p-dataview-content {
		background: var(--surface-0);
		color: var(--text-color);
		border: 0 none;
		padding: 0;
	}
	.p-dataview-footer {
		background: var(--surface-b);
		color: var(--text-color);
		border: 1px solid var(--surface-c);
		border-width: 0 0 1px 0;
		padding: 1rem 1rem;
		font-weight: 600;
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;
	}
	.p-dataview-loading-icon {
		font-size: 2rem;
	}
	.p-dataview-emptymessage {
		padding: 1rem;
	}
}
.p-dataview.p-dataview-list {
	.p-dataview-content {
		>.p-grid {
			>div {
				border: solid var(--surface-c);
				border-width: 0 0 1px 0;
			}
		}
	}
}
.p-column-filter-row {
	.p-column-filter-menu-button {
		margin-left: 0.5rem;
	}
	.p-column-filter-clear-button {
		margin-left: 0.5rem;
	}
}
.p-column-filter-menu-button {
	width: 2rem;
	height: 2rem;
	color: var(--text-color-secondary);
	border: 0 none;
	background: transparent;
	border-radius: 50%;
	transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
	&:hover {
		color: var(--text-color);
		border-color: transparent;
		background: var(--surface-c);
	}
	&:focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem var(--shadow-color);
	}
}
.p-column-filter-menu-button.p-column-filter-menu-button-open {
	background: var(--surface-c);
	color: var(--text-color);
	&:hover {
		background: var(--surface-c);
		color: var(--text-color);
	}
}
.p-column-filter-menu-button.p-column-filter-menu-button-active {
	background: var(--background-panel);
	color: var(--text-color);
	&:hover {
		background: var(--background-panel);
		color: var(--text-color);
	}
}
.p-column-filter-clear-button {
	width: 2rem;
	height: 2rem;
	color: var(--text-color-secondary);
	border: 0 none;
	background: transparent;
	border-radius: 50%;
	transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
	&:hover {
		color: var(--text-color);
		border-color: transparent;
		background: var(--surface-c);
	}
	&:focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem var(--shadow-color);
	}
}
.p-column-filter-overlay {
	background: var(--surface-0);
	color: var(--text-color);
	border: 0 none;
	border-radius: 3px;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
	min-width: 12.5rem;
	.p-column-filter-row-items {
		padding: 0.5rem 0;
		.p-column-filter-row-item {
			margin: 0;
			padding: 0.5rem 1rem;
			border: 0 none;
			color: var(--text-color);
			background: transparent;
			transition: box-shadow 0.2s;
			border-radius: 0;
			&:not(.p-highlight) {
				&:not(.p-disabled) {
					&:hover {
						color: var(--text-color);
						background: var(--surface-c);
					}
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: inset 0 0 0 0.15rem var(--shadow-color);
			}
		}
		.p-column-filter-row-item.p-highlight {
			color: var(--text-color);
			background: var(--background-panel);
		}
		.p-column-filter-separator {
			border-top: 1px solid var(--surface-d);
			margin: 0.25rem 0;
		}
	}
}
.p-column-filter-overlay-menu {
	.p-column-filter-operator {
		padding: 0.5rem 1rem;
		border-bottom: 0 none;
		color: var(--text-color);
		background: var(--surface-b);
		margin: 0;
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
	}
	.p-column-filter-constraint {
		padding: 1rem;
		border-bottom: 1px solid var(--surface-d);
		.p-column-filter-matchmode-dropdown {
			margin-bottom: 0.5rem;
		}
		.p-column-filter-remove-button {
			margin-top: 0.5rem;
		}
		&:last-child {
			border-bottom: 0 none;
		}
	}
	.p-column-filter-add-rule {
		padding: 0.5rem 1rem;
	}
	.p-column-filter-buttonbar {
		padding: 1rem;
	}
}
.fc {
	.fc-view-container {
		th {
			background: var(--surface-b);
			border: 1px solid var(--surface-d);
			color: var(--text-color);
		}
		td.fc-widget-content {
			background: var(--surface-0);
			border: 1px solid var(--surface-d);
			color: var(--text-color);
		}
		td.fc-head-container {
			border: 1px solid var(--surface-d);
		}
		.fc-row {
			border-right: 1px solid var(--surface-d);
		}
		.fc-event {
			background: var(--button-hover);
			border: 1px solid var(--button-hover);
			color: var(--primary-color-text);
		}
		.fc-divider {
			background: var(--surface-b);
			border: 1px solid var(--surface-d);
		}
	}
	.fc-toolbar {
		.fc-button {
			color: var(--primary-color-text);
			background: var(--primary-color);
			border: 1px solid var(--primary-color);
			font-size: 1rem;
			transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
			border-radius: 3px;
			display: flex;
			align-items: center;
			&:enabled {
				&:hover {
					background: var(--button-hover);
					color: var(--primary-color-text);
					border-color: var(--button-hover);
				}
				&:active {
					background: var(--element-hover);
					color: var(--primary-color-text);
					border-color: var(--element-hover);
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						box-shadow: 0 0 0 0.2rem var(--shadow-color);
					}
				}
			}
			.fc-icon-chevron-left {
				font-family: "PrimeIcons" !important;
				text-indent: 0;
				font-size: 1rem;
				&:before {
					content: "";
				}
			}
			.fc-icon-chevron-right {
				font-family: "PrimeIcons" !important;
				text-indent: 0;
				font-size: 1rem;
				&:before {
					content: "";
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: 0 0 0 0.2rem var(--shadow-color);
			}
		}
		.fc-button.fc-dayGridMonth-button {
			background: var(--surface-0);
			border: 1px solid var(--panel-border);
			color: var(--text-color);
			transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
			&:hover {
				background: var(--surface-c);
				border-color: var(--panel-border);
				color: var(--text-color);
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: 0 0 0 0.2rem var(--shadow-color);
				z-index: 1;
			}
		}
		.fc-button.fc-timeGridWeek-button {
			background: var(--surface-0);
			border: 1px solid var(--panel-border);
			color: var(--text-color);
			transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
			&:hover {
				background: var(--surface-c);
				border-color: var(--panel-border);
				color: var(--text-color);
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: 0 0 0 0.2rem var(--shadow-color);
				z-index: 1;
			}
		}
		.fc-button.fc-timeGridDay-button {
			background: var(--surface-0);
			border: 1px solid var(--panel-border);
			color: var(--text-color);
			transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
			&:hover {
				background: var(--surface-c);
				border-color: var(--panel-border);
				color: var(--text-color);
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: 0 0 0 0.2rem var(--shadow-color);
				z-index: 1;
			}
		}
		.fc-button.fc-dayGridMonth-button.fc-button-active {
			background: var(--primary-color);
			border-color: var(--primary-color);
			color: var(--primary-color-text);
			&:hover {
				background: var(--button-hover);
				border-color: var(--button-hover);
				color: var(--primary-color-text);
			}
		}
		.fc-button.fc-timeGridWeek-button.fc-button-active {
			background: var(--primary-color);
			border-color: var(--primary-color);
			color: var(--primary-color-text);
			&:hover {
				background: var(--button-hover);
				border-color: var(--button-hover);
				color: var(--primary-color-text);
			}
		}
		.fc-button.fc-timeGridDay-button.fc-button-active {
			background: var(--primary-color);
			border-color: var(--primary-color);
			color: var(--primary-color-text);
			&:hover {
				background: var(--button-hover);
				border-color: var(--button-hover);
				color: var(--primary-color-text);
			}
		}
		.fc-button-group {
			.fc-button {
				border-radius: 0;
				&:first-child {
					border-top-left-radius: 3px;
					border-bottom-left-radius: 3px;
				}
				&:last-child {
					border-top-right-radius: 3px;
					border-bottom-right-radius: 3px;
				}
			}
		}
	}
}
.p-orderlist {
	.p-orderlist-controls {
		padding: 1rem;
		.p-button {
			margin-bottom: 0.5rem;
		}
	}
	.p-orderlist-header {
		background: var(--surface-b);
		color: var(--text-color);
		border: 1px solid var(--surface-d);
		padding: 1rem;
		border-bottom: 0 none;
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
		.p-orderlist-title {
			font-weight: 600;
		}
	}
	.p-orderlist-filter-container {
		padding: 1rem;
		background: var(--surface-0);
		border: 1px solid var(--surface-d);
		border-bottom: 0 none;
		.p-orderlist-filter-input {
			padding-right: 1.5rem;
		}
		.p-orderlist-filter-icon {
			right: 0.5rem;
			color: var(--text-color-secondary);
		}
	}
	.p-orderlist-list {
		border: 1px solid var(--surface-d);
		background: var(--surface-0);
		color: var(--text-color);
		padding: 0.5rem 0;
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
		.p-orderlist-item {
			padding: 0.5rem 1rem;
			margin: 0;
			border: 0 none;
			color: var(--text-color);
			background: transparent;
			transition: box-shadow 0.2s;
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: inset 0 0 0 0.15rem var(--shadow-color);
			}
		}
		.p-orderlist-item.p-highlight {
			color: var(--text-color);
			background: var(--background-panel);
		}
		&:not(.cdk-drop-list-dragging) {
			.p-orderlist-item {
				&:not(.p-highlight) {
					&:hover {
						background: var(--surface-c);
						color: var(--text-color);
					}
				}
			}
		}
	}
}
.p-orderlist-item.cdk-drag-preview {
	padding: 0.5rem 1rem;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
	border: 0 none;
	color: var(--text-color);
	background: var(--surface-0);
	margin: 0;
}
.p-organizationchart {
	.p-organizationchart-node-content.p-organizationchart-selectable-node {
		&:not(.p-highlight) {
			&:hover {
				background: var(--surface-c);
				color: var(--text-color);
			}
		}
	}
	.p-organizationchart-node-content.p-highlight {
		background: var(--background-panel);
		color: var(--text-color);
		.p-node-toggler {
			i {
				color: #ffc261;
			}
		}
	}
	.p-organizationchart-line-down {
		background: var(--surface-d);
	}
	.p-organizationchart-line-left {
		border-right: 1px solid var(--surface-d);
		border-color: var(--surface-d);
	}
	.p-organizationchart-line-top {
		border-top: 1px solid var(--surface-d);
		border-color: var(--surface-d);
	}
	.p-organizationchart-node-content {
		border: 1px solid var(--surface-d);
		background: var(--surface-0);
		color: var(--text-color);
		padding: 1rem;
		.p-node-toggler {
			background: inherit;
			color: inherit;
			border-radius: 50%;
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: 0 0 0 0.2rem var(--shadow-color);
			}
		}
	}
}
.p-paginator {
	background: var(--surface-0);
	color: var(--text-color-secondary);
	border: solid var(--surface-c);
	border-width: 0;
	padding: 0.5rem 1rem;
	border-radius: 3px;
	.p-paginator-first {
		background-color: transparent;
		border: 0 none;
		color: var(--text-color-secondary);
		min-width: 2.357rem;
		height: 2.357rem;
		margin: 0.143rem;
		transition: box-shadow 0.2s;
		border-radius: 3px;
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
		&:not(.p-disabled) {
			&:not(.p-highlight) {
				&:hover {
					background: var(--surface-c);
					border-color: transparent;
					color: var(--text-color);
				}
			}
		}
	}
	.p-paginator-prev {
		background-color: transparent;
		border: 0 none;
		color: var(--text-color-secondary);
		min-width: 2.357rem;
		height: 2.357rem;
		margin: 0.143rem;
		transition: box-shadow 0.2s;
		border-radius: 3px;
		&:not(.p-disabled) {
			&:not(.p-highlight) {
				&:hover {
					background: var(--surface-c);
					border-color: transparent;
					color: var(--text-color);
				}
			}
		}
	}
	.p-paginator-next {
		background-color: transparent;
		border: 0 none;
		color: var(--text-color-secondary);
		min-width: 2.357rem;
		height: 2.357rem;
		margin: 0.143rem;
		transition: box-shadow 0.2s;
		border-radius: 3px;
		&:not(.p-disabled) {
			&:not(.p-highlight) {
				&:hover {
					background: var(--surface-c);
					border-color: transparent;
					color: var(--text-color);
				}
			}
		}
	}
	.p-paginator-last {
		background-color: transparent;
		border: 0 none;
		color: var(--text-color-secondary);
		min-width: 2.357rem;
		height: 2.357rem;
		margin: 0.143rem;
		transition: box-shadow 0.2s;
		border-radius: 3px;
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
		&:not(.p-disabled) {
			&:not(.p-highlight) {
				&:hover {
					background: var(--surface-c);
					border-color: transparent;
					color: var(--text-color);
				}
			}
		}
	}
	.p-dropdown {
		margin-left: 0.5rem;
		height: 2.357rem;
		.p-dropdown-label {
			padding-right: 0;
		}
	}
	.p-paginator-current {
		background-color: transparent;
		border: 0 none;
		color: var(--text-color-secondary);
		min-width: 2.357rem;
		height: 2.357rem;
		margin: 0.143rem;
		padding: 0 0.5rem;
	}
	.p-paginator-pages {
		.p-paginator-page {
			background-color: transparent;
			border: 0 none;
			color: var(--text-color-secondary);
			min-width: 2.357rem;
			height: 2.357rem;
			margin: 0.143rem;
			transition: box-shadow 0.2s;
			border-radius: 3px;
			&:not(.p-highlight) {
				&:hover {
					background: var(--surface-c);
					border-color: transparent;
					color: var(--text-color);
				}
			}
		}
		.p-paginator-page.p-highlight {
			background: var(--background-panel);
			border-color: var(--background-panel);
			color: var(--text-color);
		}
	}
}
.p-picklist {
	.p-picklist-buttons {
		padding: 1rem;
		.p-button {
			margin-bottom: 0.5rem;
		}
	}
	.p-picklist-header {
		background: var(--surface-b);
		color: var(--text-color);
		border: 1px solid var(--surface-d);
		padding: 1rem;
		border-bottom: 0 none;
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
		.p-picklist-title {
			font-weight: 600;
		}
	}
	.p-picklist-filter-container {
		padding: 1rem;
		background: var(--surface-0);
		border: 1px solid var(--surface-d);
		border-bottom: 0 none;
		.p-picklist-filter-input {
			padding-right: 1.5rem;
		}
		.p-picklist-filter-icon {
			right: 0.5rem;
			color: var(--text-color-secondary);
		}
	}
	.p-picklist-list {
		border: 1px solid var(--surface-d);
		background: var(--surface-0);
		color: var(--text-color);
		padding: 0.5rem 0;
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
		.p-picklist-item {
			padding: 0.5rem 1rem;
			margin: 0;
			border: 0 none;
			color: var(--text-color);
			background: transparent;
			transition: box-shadow 0.2s;
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: inset 0 0 0 0.15rem var(--shadow-color);
			}
		}
		.p-picklist-item.p-highlight {
			color: var(--text-color);
			background: var(--background-panel);
		}
		.p-picklist-empty-message {
			padding: 0.5rem 1rem;
			color: var(--text-color);
		}
		&:not(.cdk-drop-list-dragging) {
			.p-picklist-item {
				&:not(.p-highlight) {
					&:hover {
						background: var(--surface-c);
						color: var(--text-color);
					}
				}
			}
		}
	}
}
.p-picklist-item.cdk-drag-preview {
	padding: 0.5rem 1rem;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
	border: 0 none;
	color: var(--text-color);
	background: var(--surface-0);
	margin: 0;
}
.p-timeline {
	.p-timeline-event-marker {
		border: 2px solid var(--primary-color);
		border-radius: 50%;
		width: 1rem;
		height: 1rem;
		background-color: var(--surface-0);
	}
	.p-timeline-event-connector {
		background-color: var(--surface-d);
	}
}
.p-timeline.p-timeline-vertical {
	.p-timeline-event-opposite {
		padding: 0 1rem;
	}
	.p-timeline-event-content {
		padding: 0 1rem;
	}
	.p-timeline-event-connector {
		width: 2px;
	}
}
.p-timeline.p-timeline-horizontal {
	.p-timeline-event-opposite {
		padding: 1rem 0;
	}
	.p-timeline-event-content {
		padding: 1rem 0;
	}
	.p-timeline-event-connector {
		height: 2px;
	}
}
.p-tree {
	border: 1px solid var(--surface-d);
	background: var(--surface-0);
	color: var(--text-color);
	padding: 1rem;
	border-radius: 3px;
	.p-tree-container {
		.p-treenode {
			padding: 0.143rem;
			.p-treenode-content {
				border-radius: 3px;
				transition: box-shadow 0.2s;
				padding: 0.5rem;
				.p-tree-toggler {
					margin-right: 0.5rem;
					width: 2rem;
					height: 2rem;
					color: var(--text-color-secondary);
					border: 0 none;
					background: transparent;
					border-radius: 50%;
					transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
					&:enabled {
						&:hover {
							color: var(--text-color);
							border-color: transparent;
							background: var(--surface-c);
						}
					}
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						box-shadow: 0 0 0 0.2rem var(--shadow-color);
					}
				}
				.p-treenode-icon {
					margin-right: 0.5rem;
					color: var(--text-color-secondary);
				}
				.p-checkbox {
					margin-right: 0.5rem;
					.p-indeterminate {
						.p-checkbox-icon {
							color: var(--text-color);
						}
					}
				}
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					box-shadow: 0 0 0 0.2rem var(--shadow-color);
				}
			}
			.p-treenode-content.p-highlight {
				background: var(--background-panel);
				color: var(--text-color);
				.p-tree-toggler {
					color: var(--text-color);
					&:hover {
						color: var(--text-color);
					}
				}
				.p-treenode-icon {
					color: var(--text-color);
					&:hover {
						color: var(--text-color);
					}
				}
			}
			.p-treenode-content.p-treenode-selectable {
				&:not(.p-highlight) {
					&:hover {
						background: var(--surface-c);
						color: var(--text-color);
					}
				}
			}
			.p-treenode-content.p-treenode-dragover {
				background: var(--surface-c);
				color: var(--text-color);
			}
		}
	}
	.p-tree-filter-container {
		margin-bottom: 0.5rem;
		.p-tree-filter {
			width: 100%;
			padding-right: 1.5rem;
		}
		.p-tree-filter-icon {
			right: 0.5rem;
			color: var(--text-color-secondary);
		}
	}
	.p-treenode-children {
		padding: 0 0 0 1rem;
	}
	.p-tree-loading-icon {
		font-size: 2rem;
	}
	.p-treenode-droppoint.p-treenode-droppoint-active {
		background-color: #ffce80;
	}
}
.p-tree.p-tree-horizontal {
	.p-treenode {
		.p-treenode-content {
			border-radius: 3px;
			border: 1px solid var(--surface-d);
			background-color: var(--surface-0);
			color: var(--text-color);
			padding: 0.5rem;
			transition: box-shadow 0.2s;
			.p-tree-toggler {
				margin-right: 0.5rem;
			}
			.p-treenode-icon {
				color: var(--text-color-secondary);
				margin-right: 0.5rem;
			}
			.p-checkbox {
				margin-right: 0.5rem;
			}
			.p-treenode-label {
				&:not(.p-highlight) {
					&:hover {
						background-color: inherit;
						color: inherit;
					}
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: 0 0 0 0.2rem var(--shadow-color);
			}
		}
		.p-treenode-content.p-highlight {
			background-color: var(--background-panel);
			color: var(--text-color);
			.p-treenode-icon {
				color: var(--text-color);
			}
		}
		.p-treenode-content.p-treenode-selectable {
			&:not(.p-highlight) {
				&:hover {
					background: var(--surface-c);
					color: var(--text-color);
				}
			}
		}
	}
}
.p-treetable {
	.p-paginator-top {
		border-width: 0 0 1px 0;
		border-radius: 0;
	}
	.p-paginator-bottom {
		border-width: 0 0 1px 0;
		border-radius: 0;
	}
	.p-treetable-header {
		background: var(--surface-b);
		color: var(--text-color);
		border: 1px solid var(--surface-c);
		border-width: 1px 0 1px 0;
		padding: 1rem 1rem;
		font-weight: 600;
	}
	.p-treetable-footer {
		background: var(--surface-b);
		color: var(--text-color);
		border: 1px solid var(--surface-c);
		border-width: 0 0 1px 0;
		padding: 1rem 1rem;
		font-weight: 600;
	}
	.p-treetable-thead {
		>tr {
			>th {
				text-align: left;
				padding: 1rem 1rem;
				border: 1px solid var(--surface-c);
				border-width: 0 0 1px 0;
				font-weight: 600;
				color: var(--text-color);
				background: var(--surface-b);
				transition: box-shadow 0.2s;
			}
		}
	}
	.p-treetable-tfoot {
		>tr {
			>td {
				text-align: left;
				padding: 1rem 1rem;
				border: 1px solid var(--surface-c);
				border-width: 0 0 1px 0;
				font-weight: 600;
				color: var(--text-color);
				background: var(--surface-b);
			}
		}
	}
	.p-sortable-column {
		outline-color: var(--shadow-color);
		.p-sortable-column-icon {
			color: var(--text-color-secondary);
			margin-left: 0.5rem;
		}
		.p-sortable-column-badge {
			border-radius: 50%;
			height: 1.143rem;
			min-width: 1.143rem;
			line-height: 1.143rem;
			color: var(--text-color);
			background: var(--background-panel);
			margin-left: 0.5rem;
		}
		&:not(.p-highlight) {
			&:hover {
				background: var(--surface-c);
				color: var(--text-color);
				.p-sortable-column-icon {
					color: var(--text-color-secondary);
				}
			}
		}
	}
	.p-sortable-column.p-highlight {
		background: var(--surface-b);
		color: var(--primary-color);
		.p-sortable-column-icon {
			color: var(--primary-color);
		}
	}
	.p-treetable-tbody {
		>tr {
			background: var(--surface-0);
			color: var(--text-color);
			transition: box-shadow 0.2s;
			outline-color: var(--shadow-color);
			>td {
				text-align: left;
				border: 1px solid var(--surface-c);
				border-width: 0 0 1px 0;
				padding: 1rem 1rem;
				.p-treetable-toggler {
					width: 2rem;
					height: 2rem;
					color: var(--text-color-secondary);
					border: 0 none;
					background: transparent;
					border-radius: 50%;
					transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
					margin-right: 0.5rem;
					&:enabled {
						&:hover {
							color: var(--text-color);
							border-color: transparent;
							background: var(--surface-c);
						}
					}
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						box-shadow: 0 0 0 0.2rem var(--shadow-color);
					}
				}
				p-treetablecheckbox {
					.p-checkbox {
						margin-right: 0.5rem;
						.p-indeterminate {
							.p-checkbox-icon {
								color: var(--text-color);
							}
						}
					}
				}
			}
		}
		>tr.p-highlight {
			background: var(--background-panel);
			color: var(--text-color);
			.p-treetable-toggler {
				color: var(--text-color);
				&:hover {
					color: var(--text-color);
				}
			}
		}
	}
	.p-column-resizer-helper {
		background: var(--primary-color);
	}
	.p-treetable-scrollable-header {
		background: var(--surface-b);
	}
	.p-treetable-scrollable-footer {
		background: var(--surface-b);
	}
	.p-treetable-loading-icon {
		font-size: 2rem;
	}
}
.p-treetable.p-treetable-hoverable-rows {
	.p-treetable-tbody {
		>tr {
			&:not(.p-highlight) {
				&:hover {
					background: var(--surface-c);
					color: var(--text-color);
					.p-treetable-toggler {
						color: var(--text-color);
					}
				}
			}
		}
	}
}
.p-treetable.p-treetable-gridlines {
	.p-datatable-header {
		border-width: 1px 1px 0 1px;
	}
	.p-treetable-footer {
		border-width: 0 1px 1px 1px;
	}
	.p-treetable-top {
		border-width: 0 1px 0 1px;
	}
	.p-treetable-bottom {
		border-width: 0 1px 1px 1px;
	}
	.p-treetable-thead {
		>tr {
			>th {
				border-width: 1px;
			}
		}
	}
	.p-treetable-tbody {
		>tr {
			>td {
				border-width: 1px;
			}
		}
	}
	.p-treetable-tfoot {
		>tr {
			>td {
				border-width: 1px;
			}
		}
	}
}
.p-treetable.p-treetable-sm {
	.p-treetable-header {
		padding: 0.875rem 0.875rem;
	}
	.p-treetable-thead {
		>tr {
			>th {
				padding: 0.5rem 0.5rem;
			}
		}
	}
	.p-treetable-tbody {
		>tr {
			>td {
				padding: 0.5rem 0.5rem;
			}
		}
	}
	.p-treetable-tfoot {
		>tr {
			>td {
				padding: 0.5rem 0.5rem;
			}
		}
	}
	.p-treetable-footer {
		padding: 0.5rem 0.5rem;
	}
}
.p-treetable.p-treetable-lg {
	.p-treetable-header {
		padding: 1.25rem 1.25rem;
	}
	.p-treetable-thead {
		>tr {
			>th {
				padding: 1.25rem 1.25rem;
			}
		}
	}
	.p-treetable-tbody {
		>tr {
			>td {
				padding: 1.25rem 1.25rem;
			}
		}
	}
	.p-treetable-tfoot {
		>tr {
			>td {
				padding: 1.25rem 1.25rem;
			}
		}
	}
	.p-treetable-footer {
		padding: 1.25rem 1.25rem;
	}
}
.p-virtualscroller {
	.p-virtualscroller-header {
		background: var(--surface-b);
		color: var(--text-color);
		border: 1px solid var(--surface-c);
		border-width: 1px 0 1px 0;
		padding: 1rem 1rem;
		font-weight: 600;
	}
	.p-virtualscroller-content {
		background: var(--surface-0);
		color: var(--text-color);
		border: 0 none;
		padding: 0;
	}
	.p-virtualscroller-footer {
		background: var(--surface-b);
		color: var(--text-color);
		border: 1px solid var(--surface-c);
		border-width: 0 0 1px 0;
		padding: 1rem 1rem;
		font-weight: 600;
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;
	}
}
.p-accordion {
	.p-accordion-header {
		.p-accordion-header-link {
			padding: 1rem;
			border: 1px solid var(--surface-d);
			color: var(--text-color);
			background: var(--surface-b);
			font-weight: 600;
			border-radius: 3px;
			transition: box-shadow 0.2s;
			.p-accordion-toggle-icon {
				margin-right: 0.5rem;
			}
		}
		&:not(.p-disabled) {
			.p-accordion-header-link {
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					box-shadow: 0 0 0 0.2rem var(--shadow-color);
				}
			}
		}
		&:not(.p-highlight) {
			&:not(.p-disabled) {
				&:hover {
					.p-accordion-header-link {
						background: var(--surface-c);
						border-color: var(--surface-d);
						color: var(--text-color);
					}
				}
			}
		}
		&:not(.p-disabled).p-highlight {
			.p-accordion-header-link {
				background: var(--surface-b);
				border-color: var(--surface-d);
				color: var(--text-color);
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}
			&:hover {
				.p-accordion-header-link {
					border-color: var(--surface-d);
					background: var(--surface-c);
					color: var(--text-color);
				}
			}
		}
	}
	.p-accordion-content {
		padding: 1rem;
		border: 1px solid var(--surface-d);
		background: var(--surface-0);
		color: var(--text-color);
		border-top: 0;
		border-top-right-radius: 0;
		border-top-left-radius: 0;
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
	}
	p-accordiontab {
		.p-accordion-tab {
			margin-bottom: 0;
		}
		.p-accordion-header {
			.p-accordion-header-link {
				border-radius: 0;
			}
		}
		.p-accordion-content {
			border-radius: 0;
		}
		&:not(:first-child) {
			.p-accordion-header {
				.p-accordion-header-link {
					border-top: 0 none;
				}
				&:not(.p-highlight) {
					&:not(.p-disabled) {
						&:hover {
							.p-accordion-header-link {
								border-top: 0 none;
							}
						}
					}
				}
				&:not(.p-disabled).p-highlight {
					&:hover {
						.p-accordion-header-link {
							border-top: 0 none;
						}
					}
				}
			}
		}
		&:first-child {
			.p-accordion-header {
				.p-accordion-header-link {
					border-top-right-radius: 3px;
					border-top-left-radius: 3px;
				}
			}
		}
		&:last-child {
			.p-accordion-header {
				&:not(.p-highlight) {
					.p-accordion-header-link {
						border-bottom-right-radius: 3px;
						border-bottom-left-radius: 3px;
					}
				}
			}
			.p-accordion-content {
				border-bottom-right-radius: 3px;
				border-bottom-left-radius: 3px;
			}
		}
	}
}
.p-card {
	background: var(--surface-0);
	color: var(--text-color);
	box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
	border-radius: 3px;
	.p-card-body {
		padding: 1rem;
	}
	.p-card-title {
		font-size: 1.5rem;
		font-weight: 700;
		margin-bottom: 0.5rem;
	}
	.p-card-subtitle {
		font-weight: 400;
		margin-bottom: 0.5rem;
		color: var(--text-color-secondary);
	}
	.p-card-content {
		padding: 1rem 0;
	}
	.p-card-footer {
		padding: 1rem 0 0 0;
	}
}
.p-divider {
	.p-divider-content {
		background-color: var(--surface-0);
	}
}
.p-divider.p-divider-horizontal {
	margin: 1rem 0;
	padding: 0 1rem;
	&:before {
		border-top: 1px var(--surface-d);
	}
	.p-divider-content {
		padding: 0 0.5rem;
	}
}
.p-divider.p-divider-vertical {
	margin: 0 1rem;
	padding: 1rem 0;
	&:before {
		border-left: 1px var(--surface-d);
	}
	.p-divider-content {
		padding: 0.5rem 0;
	}
}
.p-fieldset {
	border: 1px solid var(--surface-d);
	background: var(--surface-0);
	color: var(--text-color);
	border-radius: 3px;
	.p-fieldset-legend {
		padding: 1rem;
		border: 1px solid var(--surface-d);
		color: var(--text-color);
		background: var(--surface-b);
		font-weight: 600;
		border-radius: 3px;
	}
	.p-fieldset-content {
		padding: 1rem;
	}
}
.p-fieldset.p-fieldset-toggleable {
	.p-fieldset-legend {
		padding: 0;
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		a {
			padding: 1rem;
			color: var(--text-color);
			border-radius: 3px;
			transition: box-shadow 0.2s;
			.p-fieldset-toggler {
				margin-right: 0.5rem;
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: 0 0 0 0.2rem var(--shadow-color);
			}
		}
		&:hover {
			background: var(--surface-c);
			border-color: var(--surface-d);
			color: var(--text-color);
		}
	}
}
.p-panel {
	.p-panel-header {
		border: 1px solid var(--surface-d);
		padding: 1rem;
		background: var(--surface-b);
		color: var(--text-color);
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
		.p-panel-title {
			font-weight: 600;
		}
		.p-panel-header-icon {
			width: 2rem;
			height: 2rem;
			color: var(--text-color-secondary);
			border: 0 none;
			background: transparent;
			border-radius: 50%;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			&:enabled {
				&:hover {
					color: var(--text-color);
					border-color: transparent;
					background: var(--surface-c);
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: 0 0 0 0.2rem var(--shadow-color);
			}
		}
	}
	.p-panel-content {
		padding: 1rem;
		border: 1px solid var(--surface-d);
		background: var(--surface-0);
		color: var(--text-color);
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
		// border-top: 0 none;
	}
	.p-panel-content-reachability{
		/* padding: 1rem; */
		border: 1px solid var(--surface-d);
		background: var(--surface-0);
		color: var(--text-color);
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
	}
	.p-panel-footer {
		padding: 0.5rem 1rem;
		border: 1px solid var(--surface-d);
		background: var(--surface-0);
		color: var(--text-color);
		border-top: 0 none;
	}
}

lib-obe-timeline {
	.p-tooltip {
	  min-width: 500px !important;
	  div {
		font-size: 0.75rem !important;
		display: flex !important;
		flex-direction: column !important;
		gap: 1rem !important;
	  }
	}
	.p-scrollpanel {
	  &-wrapper {
	
		overflow: hidden !important;
		width: 100% !important;
		height: 100% !important;
		position: relative !important;
		z-index: 1 !important;
		float: left !important;
	  }
	
	  &-content {
		padding: 0 !important;
	  }
	
	}
  }

.p-panel.p-panel-toggleable {
	.p-panel-header {
		padding: 0.5rem 1rem;
	}
}
.p-scrollpanel {
  .p-scrollpanel-content {
    // display: flex;
    // flex-direction: column;
    // gap: 1rem;
    padding: 0 18px 18px 18px;
  }
	.p-scrollpanel-bar {
		// background: var(--surface-b);
		border: 0 none;
	}
}
.p-splitter {
	border: 1px solid var(--surface-d);
	background: var(--surface-0);
	border-radius: 3px;
	color: var(--text-color);
	.p-splitter-gutter {
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		background: var(--surface-b);
		.p-splitter-gutter-handle {
			background: var(--surface-d);
		}
	}
	.p-splitter-gutter-resizing {
		background: var(--surface-d);
	}
}
.p-tabview {
	.p-tabview-nav {
		background: var(--surface-0);
		border: 1px solid var(--surface-d);
		border-width: 0 0 3px 0;

		li {
			margin-right: 0;
      border: 3px solid var(--surface-d);
      border-top-left-radius: 15px;
      margin-bottom: -3px;
      border-top-right-radius: 15px;
      border-bottom: 0px solid;
      background-color: var(--surface-100);
      margin-right:0.25rem;
      &:active {
        // box-shadow: 0 0 0 0.2rem var(--shadow-color);
        z-index:10 ;
      }
			.p-tabview-nav-link {
				border: solid var(--surface-d);
				border-width: 0 0 3px 0;
				border-color: transparent transparent var(--surface-d) transparent;
				background: var(--surface-100);
				color: var(--text-color-secondary);
				padding: 1rem;
				font-weight: 600;
				border-top-right-radius: 3px;
				border-top-left-radius: 3px;
				transition: box-shadow 0.2s;
				&:not(.p-disabled) {
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						// box-shadow: 0 0 0 0.2rem var(--shadow-color);
					}
				}
			}
			&:not(.p-highlight) {
				&:not(.p-disabled) {
					&:hover {
            background-color: var(--surface-0);
						.p-tabview-nav-link {
							background: var(--surface-0);
							border-color: var(--text-color-secondary);
							color: var(--text-color-secondary);
						}
					}
				}
			}
		}
		li.p-highlight {
      background-color: white;

			.p-tabview-nav-link {
				background: var(--surface-0);
				border-color: var(--primary-color);
				color: var(--primary-color);
			}
		}
	}
	.p-tabview-left-icon {
		margin-right: 0.5rem;
	}
	.p-tabview-right-icon {
		margin-left: 0.5rem;
	}
	.p-tabview-close {
		margin-left: 0.5rem;
	}
	.p-tabview-panels {
		background: var(--surface-0);
		padding: 1rem;
		border: 0 none;
		color: var(--text-color);
		// border-bottom-right-radius: 3px;
		// border-bottom-left-radius: 3px;
    border-left: 3px solid var(--surface-d);
    border-right: 3px solid var(--surface-d);
    border-bottom: 3px solid var(--surface-d);
	}
}
.p-toolbar {
	background: var(--surface-b);
	border: 1px solid var(--surface-d);
	padding: 1rem;
	border-radius: 3px;
	.p-toolbar-separator {
		margin: 0 0.5rem;
	}
}
.p-confirm-popup {
	background: var(--surface-0);
	color: var(--text-color);
	border: 0 none;
	border-radius: 3px;
	box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	.p-confirm-popup-content {
		padding: 1rem;
	}
	.p-confirm-popup-footer {
		text-align: right;
		padding: 0.5rem 1rem;
		button {
			margin: 0 0.5rem 0 0;
			width: auto;
			&:last-child {
				margin: 0;
			}
		}
	}
	&:after {
		border: solid transparent;
		border-color: rgba(255, 255, 255, 0);
		border-bottom-color: var(--surface-0);
	}
	&:before {
		border: solid transparent;
		border-color: rgba(255, 255, 255, 0);
		border-bottom-color: var(--surface-0);
	}
	.p-confirm-popup-icon {
		font-size: 1.5rem;
	}
	.p-confirm-popup-message {
		margin-left: 1rem;
	}
}
.p-confirm-popup.p-confirm-popup-flipped {
	&:after {
		border-top-color: var(--surface-0);
	}
	&:before {
		border-top-color: var(--surface-0);
	}
}
.p-dialog {
	border-radius: 3px;
	box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	border: 0 none;
	.p-dialog-header {
		border-bottom: 0 none;
		background: var(--surface-0);
		color: var(--text-color);
		padding: 1.5rem;
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
		.p-dialog-title {
			font-weight: 600;
			font-size: 1.25rem;
		}
		.p-dialog-header-icon {
			width: 2rem;
			height: 2rem;
			color: var(--text-color-secondary);
			border: 0 none;
			background: transparent;
			border-radius: 50%;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			margin-right: 0.5rem;
			&:enabled {
				&:hover {
					color: var(--text-color);
					border-color: transparent;
					background: var(--surface-c);
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: 0 0 0 0.2rem var(--shadow-color);
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
	.p-dialog-content {
		background: var(--surface-0);
		color: var(--text-color);
		padding: 0 1.5rem 2rem 1.5rem;
	}
	.p-dialog-footer {
		border-top: 0 none;
		background: var(--surface-0);
		color: var(--text-color);
		padding: 0 1.5rem 1.5rem 1.5rem;
		text-align: right;
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
		button {
			margin: 0 0.5rem 0 0;
			width: auto;
		}
	}
}
.p-dialog.p-confirm-dialog {
	.p-confirm-dialog-icon {
		font-size: 2rem;
	}
	.p-confirm-dialog-message {
		margin-left: 1rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    flex: 1;
    p:last-child{
      margin-top: 1rem;
    }
	}
}
.p-dialog-mask.p-component-overlay {
	background-color: rgba(0, 0, 0, 0.4);
}
.p-overlaypanel {
	background: var(--surface-0);
	color: var(--text-color);
	border: 0 none;
	border-radius: 3px;
	box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	.p-overlaypanel-content {
		padding: 1rem;
	}
	.p-overlaypanel-close {
		background: var(--primary-color);
		color: var(--primary-color-text);
		width: 2rem;
		height: 2rem;
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		border-radius: 50%;
		position: absolute;
		top: -1rem;
		right: -1rem;
		&:enabled {
			&:hover {
				background: var(--button-hover);
				color: var(--primary-color-text);
			}
		}
	}
	&:after {
		border: solid transparent;
		border-color: rgba(255, 255, 255, 0);
		border-bottom-color: var(--surface-0);
	}
	&:before {
		border: solid transparent;
		border-color: rgba(255, 255, 255, 0);
		border-bottom-color: var(--surface-0);
	}
}
.p-overlaypanel.p-overlaypanel-flipped {
	&:after {
		border-top-color: var(--surface-0);
	}
	&:before {
		border-top-color: var(--surface-0);
	}
}
.p-sidebar {
	background: var(--surface-0);
	color: var(--text-color);
	padding: 1rem;
	border: 0 none;
	box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	.p-sidebar-close {
		width: 2rem;
		height: 2rem;
		color: var(--text-color-secondary);
		border: 0 none;
		background: transparent;
		border-radius: 50%;
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		&:enabled {
			&:hover {
				color: var(--text-color);
				border-color: transparent;
				background: var(--surface-c);
			}
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: 0 0 0 0.2rem var(--shadow-color);
		}
	}
}
.p-sidebar-mask.p-component-overlay {
	background: rgba(0, 0, 0, 0.4);
}
.p-tooltip {
	.p-tooltip-text {
		background: var(--text-color);
		color: var(--surface-0);
		padding: 0.5rem 0.5rem;
		box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
		border-radius: 3px;
	}
}
.p-tooltip.p-tooltip-right {
	.p-tooltip-arrow {
		border-right-color: var(--text-color);
	}
}
.p-tooltip.p-tooltip-left {
	.p-tooltip-arrow {
		border-left-color: var(--text-color);
	}
}
.p-tooltip.p-tooltip-top {
	.p-tooltip-arrow {
		border-top-color: var(--text-color);
	}
}
.p-tooltip.p-tooltip-bottom {
	.p-tooltip-arrow {
		border-bottom-color: var(--text-color);
	}
}

.p-fileupload {
	.p-fileupload-buttonbar {
		background: var(--surface-b);
		padding: 1rem;
		border: 1px solid var(--surface-d);
		color: var(--text-color);
		border-bottom: 0 none;
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
		.p-button {
			margin-right: 0.5rem;
		}
		.p-button.p-fileupload-choose.p-focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: 0 0 0 0.2rem var(--shadow-color);
		}
	}
	.p-fileupload-content {
		background: var(--surface-0);
		padding: 2rem 1rem;
		border: 1px solid var(--surface-d);
		color: var(--text-color);
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
	}
	.p-progressbar {
		height: 0.25rem;
	}
	.p-fileupload-row {
		>div {
			padding: 1rem 1rem;
		}
	}
}
.p-fileupload.p-fileupload-advanced {
	.p-message {
		margin-top: 0;
	}
}
.p-fileupload-choose {
	&:not(.p-disabled) {
		&:hover {
			background: var(--button-hover);
			color: var(--primary-color-text);
			border-color: var(--button-hover);
		}
		&:active {
			background: var(--element-hover);
			color: var(--primary-color-text);
			border-color: var(--element-hover);
		}
	}
}
.p-breadcrumb {
	background: var(--surface-0);
	border: 1px solid var(--surface-d);
	border-radius: 3px;
	padding: 1rem;
	ul {
		li {
			.p-menuitem-link {
				transition: box-shadow 0.2s;
				border-radius: 3px;
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					box-shadow: 0 0 0 0.2rem var(--shadow-color);
				}
				.p-menuitem-text {
					color: var(--text-color);
				}
				.p-menuitem-icon {
					color: var(--text-color-secondary);
				}
			}
			&:last-child {
				.p-menuitem-text {
					color: var(--text-color);
				}
				.p-menuitem-icon {
					color: var(--text-color-secondary);
				}
			}
		}
		li.p-breadcrumb-chevron {
			margin: 0 0.5rem 0 0.5rem;
			color: var(--text-color);
		}
	}
}
.p-contextmenu {
	padding: 0.25rem 0;
	background: var(--surface-0);
	color: var(--text-color);
	border: 0 none;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
	width: 12.5rem;
	.p-menuitem-link {
		padding: 0.75rem 1rem;
		color: var(--text-color);
		border-radius: 0;
		transition: box-shadow 0.2s;
		user-select: none;
		.p-menuitem-text {
			color: var(--text-color);
		}
		.p-menuitem-icon {
			color: var(--text-color-secondary);
			margin-right: 0.5rem;
		}
		.p-submenu-icon {
			color: var(--text-color-secondary);
		}
		&:not(.p-disabled) {
			&:hover {
				background: var(--surface-c);
				.p-menuitem-text {
					color: var(--text-color);
				}
				.p-menuitem-icon {
					color: var(--text-color-secondary);
				}
				.p-submenu-icon {
					color: var(--text-color-secondary);
				}
			}
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: inset 0 0 0 0.15rem var(--shadow-color);
		}
	}
	.p-submenu-list {
		padding: 0.25rem 0;
		background: var(--surface-0);
		border: 0 none;
		box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
	}
	.p-menuitem {
		margin: 0;
		&:last-child {
			margin: 0;
		}
	}
	.p-menuitem.p-menuitem-active {
		>.p-menuitem-link {
			background: var(--surface-c);
			.p-menuitem-text {
				color: var(--text-color);
			}
			.p-menuitem-icon {
				color: var(--text-color-secondary);
			}
			.p-submenu-icon {
				color: var(--text-color-secondary);
			}
		}
	}
	.p-menu-separator {
		border-top: 1px solid var(--surface-d);
		margin: 0.25rem 0;
	}
	.p-submenu-icon {
		font-size: 0.875rem;
	}
}
.p-megamenu {
	padding: 0.5rem;
	background: var(--surface-b);
	color: var(--text-color);
	border: 1px solid var(--surface-d);
	border-radius: 3px;
	.p-megamenu-root-list {
		>.p-menuitem {
			>.p-menuitem-link {
				padding: 0.75rem 1rem;
				color: var(--text-color);
				border-radius: 3px;
				transition: box-shadow 0.2s;
				user-select: none;
				.p-menuitem-text {
					color: var(--text-color);
				}
				.p-menuitem-icon {
					color: var(--text-color-secondary);
					margin-right: 0.5rem;
				}
				.p-submenu-icon {
					color: var(--text-color-secondary);
					margin-left: 0.5rem;
				}
				&:not(.p-disabled) {
					&:hover {
						background: var(--surface-c);
						.p-menuitem-text {
							color: var(--text-color);
						}
						.p-menuitem-icon {
							color: var(--text-color-secondary);
						}
						.p-submenu-icon {
							color: var(--text-color-secondary);
						}
					}
				}
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					box-shadow: inset 0 0 0 0.15rem var(--shadow-color);
				}
			}
		}
		>.p-menuitem.p-menuitem-active {
			>.p-menuitem-link {
				background: var(--surface-c);
				&:not(.p-disabled) {
					&:hover {
						background: var(--surface-c);
						.p-menuitem-text {
							color: var(--text-color);
						}
						.p-menuitem-icon {
							color: var(--text-color-secondary);
						}
						.p-submenu-icon {
							color: var(--text-color-secondary);
						}
					}
				}
				.p-menuitem-text {
					color: var(--text-color);
				}
				.p-menuitem-icon {
					color: var(--text-color-secondary);
				}
				.p-submenu-icon {
					color: var(--text-color-secondary);
				}
			}
		}
	}
	.p-menuitem-link {
		padding: 0.75rem 1rem;
		color: var(--text-color);
		border-radius: 0;
		transition: box-shadow 0.2s;
		user-select: none;
		.p-menuitem-text {
			color: var(--text-color);
		}
		.p-menuitem-icon {
			color: var(--text-color-secondary);
			margin-right: 0.5rem;
		}
		.p-submenu-icon {
			color: var(--text-color-secondary);
		}
		&:not(.p-disabled) {
			&:hover {
				background: var(--surface-c);
				.p-menuitem-text {
					color: var(--text-color);
				}
				.p-menuitem-icon {
					color: var(--text-color-secondary);
				}
				.p-submenu-icon {
					color: var(--text-color-secondary);
				}
			}
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: inset 0 0 0 0.15rem var(--shadow-color);
		}
	}
	.p-megamenu-panel {
		background: var(--surface-0);
		color: var(--text-color);
		border: 0 none;
		box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
	}
	.p-megamenu-submenu-header {
		margin: 0;
		padding: 0.75rem 1rem;
		color: var(--text-color);
		background: var(--surface-0);
		font-weight: 600;
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
	}
	.p-megamenu-submenu {
		padding: 0.25rem 0;
		width: 12.5rem;
		.p-menu-separator {
			border-top: 1px solid var(--surface-d);
			margin: 0.25rem 0;
		}
		.p-menuitem {
			margin: 0;
			&:last-child {
				margin: 0;
			}
		}
	}
	.p-menuitem.p-menuitem-active {
		>.p-menuitem-link {
			background: var(--surface-c);
			.p-menuitem-text {
				color: var(--text-color);
			}
			.p-menuitem-icon {
				color: var(--text-color-secondary);
			}
			.p-submenu-icon {
				color: var(--text-color-secondary);
			}
		}
	}
}
.p-megamenu.p-megamenu-vertical {
	width: 12.5rem;
	padding: 0.25rem 0;
	.p-menuitem {
		margin: 0;
		&:last-child {
			margin: 0;
		}
	}
}
.p-menu {
	padding: 0.25rem 0;
	background: var(--surface-0);
	color: var(--text-color);
	border: 1px solid var(--surface-d);
	border-radius: 3px;
	width: 12.5rem;
	.p-menuitem-link {
		padding: 0.75rem 1rem;
		color: var(--text-color);
		border-radius: 0;
		transition: box-shadow 0.2s;
		user-select: none;
		.p-menuitem-text {
			color: var(--text-color);
		}
		.p-menuitem-icon {
			color: var(--text-color-secondary);
			margin-right: 0.5rem;
		}
		.p-submenu-icon {
			color: var(--text-color-secondary);
		}
		&:not(.p-disabled) {
			&:hover {
				background: var(--surface-c);
				.p-menuitem-text {
					color: var(--text-color);
				}
				.p-menuitem-icon {
					color: var(--text-color-secondary);
				}
				.p-submenu-icon {
					color: var(--text-color-secondary);
				}
			}
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: inset 0 0 0 0.15rem var(--shadow-color);
		}
	}
	.p-submenu-header {
		margin: 0;
		padding: 0.75rem 1rem;
		color: var(--text-color);
		background: var(--surface-0);
		font-weight: 600;
		border-top-right-radius: 0;
		border-top-left-radius: 0;
	}
	.p-menu-separator {
		border-top: 1px solid var(--surface-d);
		margin: 0.25rem 0;
	}
	.p-menuitem {
		margin: 0;
		&:last-child {
			margin: 0;
		}
	}
}
.p-menu.p-menu-overlay {
	background: var(--surface-0);
	border: 0 none;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-menubar {
	padding: 0.5rem;
	background: var(--surface-b);
	color: var(--text-color);
	border: 1px solid var(--surface-d);
	border-radius: 3px;
	.p-menuitem-link {
		padding: 0.75rem 1rem;
		color: var(--text-color);
		border-radius: 0;
		transition: box-shadow 0.2s;
		user-select: none;
		.p-menuitem-text {
			color: var(--text-color);
		}
		.p-menuitem-icon {
			color: var(--text-color-secondary);
			margin-right: 0.5rem;
		}
		.p-submenu-icon {
			color: var(--text-color-secondary);
		}
		&:not(.p-disabled) {
			&:hover {
				background: var(--surface-c);
				.p-menuitem-text {
					color: var(--text-color);
				}
				.p-menuitem-icon {
					color: var(--text-color-secondary);
				}
				.p-submenu-icon {
					color: var(--text-color-secondary);
				}
			}
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: inset 0 0 0 0.15rem var(--shadow-color);
		}
	}
	.p-menubar-root-list {
		>.p-menuitem {
			>.p-menuitem-link {
				padding: 0.75rem 1rem;
				color: var(--text-color);
				border-radius: 3px;
				transition: box-shadow 0.2s;
				user-select: none;
				.p-menuitem-text {
					color: var(--text-color);
				}
				.p-menuitem-icon {
					color: var(--text-color-secondary);
					margin-right: 0.5rem;
				}
				.p-submenu-icon {
					color: var(--text-color-secondary);
					margin-left: 0.5rem;
				}
				&:not(.p-disabled) {
					&:hover {
						background: var(--surface-c);
						.p-menuitem-text {
							color: var(--text-color);
						}
						.p-menuitem-icon {
							color: var(--text-color-secondary);
						}
						.p-submenu-icon {
							color: var(--text-color-secondary);
						}
					}
				}
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					box-shadow: inset 0 0 0 0.15rem var(--shadow-color);
				}
			}
		}
		>.p-menuitem.p-menuitem-active {
			>.p-menuitem-link {
				background: var(--surface-c);
				&:not(.p-disabled) {
					&:hover {
						background: var(--surface-c);
						.p-menuitem-text {
							color: var(--text-color);
						}
						.p-menuitem-icon {
							color: var(--text-color-secondary);
						}
						.p-submenu-icon {
							color: var(--text-color-secondary);
						}
					}
				}
				.p-menuitem-text {
					color: var(--text-color);
				}
				.p-menuitem-icon {
					color: var(--text-color-secondary);
				}
				.p-submenu-icon {
					color: var(--text-color-secondary);
				}
			}
		}
	}
	.p-submenu-list {
		padding: 0.25rem 0;
		background: var(--surface-0);
		border: 0 none;
		box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
		width: 12.5rem;
		.p-menu-separator {
			border-top: 1px solid var(--surface-d);
			margin: 0.25rem 0;
		}
		.p-submenu-icon {
			font-size: 0.875rem;
		}
		.p-menuitem {
			margin: 0;
			&:last-child {
				margin: 0;
			}
		}
	}
	.p-menuitem.p-menuitem-active {
		>.p-menuitem-link {
			background: var(--surface-c);
			.p-menuitem-text {
				color: var(--text-color);
			}
			.p-menuitem-icon {
				color: var(--text-color-secondary);
			}
			.p-submenu-icon {
				color: var(--text-color-secondary);
			}
		}
	}
}
.p-panelmenu {
	.p-panelmenu-header {
		>a {
			padding: 1rem;
			border: 1px solid var(--surface-d);
			color: var(--text-color);
			background: var(--surface-b);
			font-weight: 600;
			border-radius: 3px;
			transition: box-shadow 0.2s;
			.p-panelmenu-icon {
				margin-right: 0.5rem;
			}
			.p-menuitem-icon {
				margin-right: 0.5rem;
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: 0 0 0 0.2rem var(--shadow-color);
			}
		}
		&:not(.p-highlight) {
			&:not(.p-disabled) {
				>a {
					&:hover {
						background: var(--surface-c);
						border-color: var(--surface-d);
						color: var(--text-color);
					}
				}
			}
		}
	}
	.p-panelmenu-header.p-highlight {
		margin-bottom: 0;
		>a {
			background: var(--surface-b);
			border-color: var(--surface-d);
			color: var(--text-color);
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}
		&:not(.p-disabled) {
			>a {
				&:hover {
					border-color: var(--surface-d);
					background: var(--surface-c);
					color: var(--text-color);
				}
			}
		}
	}
	.p-panelmenu-content {
		padding: 0.25rem 0;
		border: 1px solid var(--surface-d);
		background: var(--surface-0);
		color: var(--text-color);
		margin-bottom: 0;
		border-top: 0;
		border-top-right-radius: 0;
		border-top-left-radius: 0;
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
		.p-menuitem {
			.p-menuitem-link {
				padding: 0.75rem 1rem;
				color: var(--text-color);
				border-radius: 0;
				transition: box-shadow 0.2s;
				user-select: none;
				.p-menuitem-text {
					color: var(--text-color);
				}
				.p-menuitem-icon {
					color: var(--text-color-secondary);
					margin-right: 0.5rem;
				}
				.p-submenu-icon {
					color: var(--text-color-secondary);
				}
				&:not(.p-disabled) {
					&:hover {
						background: var(--surface-c);
						.p-menuitem-text {
							color: var(--text-color);
						}
						.p-menuitem-icon {
							color: var(--text-color-secondary);
						}
						.p-submenu-icon {
							color: var(--text-color-secondary);
						}
					}
				}
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					box-shadow: inset 0 0 0 0.15rem var(--shadow-color);
				}
				.p-panelmenu-icon {
					margin-right: 0.5rem;
				}
			}
		}
		.p-submenu-list {
			&:not(.p-panelmenu-root-submenu) {
				padding: 0 0 0 1rem;
			}
		}
	}
	.p-panelmenu-panel {
		margin-bottom: 0;
		.p-panelmenu-header {
			>a {
				border-radius: 0;
			}
		}
		.p-panelmenu-content {
			border-radius: 0;
		}
		&:not(:first-child) {
			.p-panelmenu-header {
				>a {
					border-top: 0 none;
				}
				&:not(.p-highlight) {
					&:not(.p-disabled) {
						&:hover {
							>a {
								border-top: 0 none;
							}
						}
					}
				}
				&:not(.p-disabled).p-highlight {
					&:hover {
						>a {
							border-top: 0 none;
						}
					}
				}
			}
		}
		&:first-child {
			.p-panelmenu-header {
				>a {
					border-top-right-radius: 3px;
					border-top-left-radius: 3px;
				}
			}
		}
		&:last-child {
			.p-panelmenu-header {
				&:not(.p-highlight) {
					>a {
						border-bottom-right-radius: 3px;
						border-bottom-left-radius: 3px;
					}
				}
			}
			.p-panelmenu-content {
				border-bottom-right-radius: 3px;
				border-bottom-left-radius: 3px;
			}
		}
	}
}
.p-slidemenu {
	padding: 0.25rem 0;
	background: var(--surface-0);
	color: var(--text-color);
	border: 1px solid var(--surface-d);
	border-radius: 3px;
	width: 12.5rem;
	.p-menuitem-link {
		padding: 0.75rem 1rem;
		color: var(--text-color);
		border-radius: 0;
		transition: box-shadow 0.2s;
		user-select: none;
		.p-menuitem-text {
			color: var(--text-color);
		}
		.p-menuitem-icon {
			color: var(--text-color-secondary);
			margin-right: 0.5rem;
		}
		.p-submenu-icon {
			color: var(--text-color-secondary);
		}
		&:not(.p-disabled) {
			&:hover {
				background: var(--surface-c);
				.p-menuitem-text {
					color: var(--text-color);
				}
				.p-menuitem-icon {
					color: var(--text-color-secondary);
				}
				.p-submenu-icon {
					color: var(--text-color-secondary);
				}
			}
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: inset 0 0 0 0.15rem var(--shadow-color);
		}
	}
	.p-slidemenu-list {
		padding: 0.25rem 0;
		background: var(--surface-0);
		border: 0 none;
		box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
	}
	.p-slidemenu.p-slidemenu-active {
		>.p-slidemenu-link {
			background: var(--surface-c);
			.p-slidemenu-text {
				color: var(--text-color);
			}
			.p-slidemenu-icon {
				color: var(--text-color-secondary);
				color: var(--text-color-secondary);
			}
		}
	}
	.p-slidemenu-separator {
		border-top: 1px solid var(--surface-d);
		margin: 0.25rem 0;
	}
	.p-slidemenu-icon {
		font-size: 0.875rem;
	}
	.p-slidemenu-backward {
		padding: 0.75rem 1rem;
		color: var(--text-color);
	}
}
.p-slidemenu.p-slidemenu-overlay {
	background: var(--surface-0);
	border: 0 none;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-steps {
	.p-steps-item {
		.p-menuitem-link {
			background: transparent;
			transition: box-shadow 0.2s;
			border-radius: 3px;
			background: var(--surface-0);
			.p-steps-number {
				color: var(--text-color);
				border: 1px solid var(--surface-c);
				background: var(--surface-0);
				min-width: 2rem;
				height: 2rem;
				line-height: 2rem;
				font-size: 1.143rem;
				z-index: 1;
				border-radius: 50%;
			}
			.p-steps-title {
				margin-top: 0.5rem;
				color: var(--text-color-secondary);
			}
			&:not(.p-disabled) {
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					box-shadow: 0 0 0 0.2rem var(--shadow-color);
				}
			}
		}
		&:before {
			content: " ";
			border-top: 1px solid var(--surface-d);
			width: 100%;
			top: 50%;
			left: 0;
			display: block;
			position: absolute;
			margin-top: -1rem;
		}
	}
	.p-steps-item.p-highlight {
		.p-steps-number {
			background: var(--background-panel);
			color: var(--text-color);
		}
		.p-steps-title {
			font-weight: 600;
			color: var(--text-color);
		}
	}
}
.p-tabmenu {
	.p-tabmenu-nav {
		background: var(--surface-0);
		border: 1px solid var(--surface-d);
		border-width: 0 0 2px 0;
		.p-tabmenuitem {
			margin-right: 0;
			.p-menuitem-link {
				border: solid var(--surface-d);
				border-width: 0 0 2px 0;
				border-color: transparent transparent var(--surface-d) transparent;
				background: var(--surface-0);
				color: var(--text-color-secondary);
				padding: 1rem;
				font-weight: 600;
				border-top-right-radius: 3px;
				border-top-left-radius: 3px;
				transition: box-shadow 0.2s;
				margin: 0 0 -2px 0;
				.p-menuitem-icon {
					margin-right: 0.5rem;
				}
				&:not(.p-disabled) {
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						box-shadow: 0 0 0 0.2rem var(--shadow-color);
					}
				}
			}
			&:not(.p-highlight) {
				&:not(.p-disabled) {
					&:hover {
						.p-menuitem-link {
							background: var(--surface-0);
							border-color: var(--text-color-secondary);
							color: var(--text-color-secondary);
						}
					}
				}
			}
		}
		.p-tabmenuitem.p-highlight {
			.p-menuitem-link {
				background: var(--surface-0);
				border-color: var(--primary-color);
				color: var(--primary-color);
			}
		}
	}
}
.p-tieredmenu {
	padding: 0.25rem 0;
	background: var(--surface-0);
	color: var(--text-color);
	border: 1px solid var(--surface-d);
	border-radius: 3px;
	width: 12.5rem;
	.p-menuitem-link {
		padding: 0.75rem 1rem;
		color: var(--text-color);
		border-radius: 0;
		transition: box-shadow 0.2s;
		user-select: none;
		.p-menuitem-text {
			color: var(--text-color);
		}
		.p-menuitem-icon {
			color: var(--text-color-secondary);
			margin-right: 0.5rem;
		}
		.p-submenu-icon {
			color: var(--text-color-secondary);
		}
		&:not(.p-disabled) {
			&:hover {
				background: var(--surface-c);
				.p-menuitem-text {
					color: var(--text-color);
				}
				.p-menuitem-icon {
					color: var(--text-color-secondary);
				}
				.p-submenu-icon {
					color: var(--text-color-secondary);
				}
			}
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: inset 0 0 0 0.15rem var(--shadow-color);
		}
	}
	.p-submenu-list {
		padding: 0.25rem 0;
		background: var(--surface-0);
		border: 0 none;
		box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
	}
	.p-menuitem {
		margin: 0;
		&:last-child {
			margin: 0;
		}
	}
	.p-menuitem.p-menuitem-active {
		>.p-menuitem-link {
			background: var(--surface-c);
			.p-menuitem-text {
				color: var(--text-color);
			}
			.p-menuitem-icon {
				color: var(--text-color-secondary);
			}
			.p-submenu-icon {
				color: var(--text-color-secondary);
			}
		}
	}
	.p-menu-separator {
		border-top: 1px solid var(--surface-d);
		margin: 0.25rem 0;
	}
	.p-submenu-icon {
		font-size: 0.875rem;
	}
}
.p-tieredmenu.p-tieredmenu-overlay {
	background: var(--surface-0);
	border: 0 none;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-inline-message {
	padding: 0.5rem 0.5rem;
	margin: 0;
	border-radius: 3px;
	.p-inline-message-icon {
		font-size: 1rem;
		margin-right: 0.5rem;
	}
	.p-inline-message-text {
		font-size: 1rem;
	}
}
.p-inline-message.p-inline-message-info {
	background: #B3E5FC;
	border: solid #0891cf;
	border-width: 0px;
	color: #044868;
	.p-inline-message-icon {
		color: #044868;
	}
}
.p-inline-message.p-inline-message-success {
	background: #C8E6C9;
	border: solid #439446;
	border-width: 0px;
	color: #224a23;
	.p-inline-message-icon {
		color: #224a23;
	}
}
.p-inline-message.p-inline-message-warn {
	background: #FFECB3;
	border: solid #d9a300;
	border-width: 0px;
	color: #6d5100;
	.p-inline-message-icon {
		color: #6d5100;
	}
}
.p-inline-message.p-inline-message-error {
	background: #FFCDD2;
	border: solid #e60017;
	border-width: 0px;
	color: #73000c;
	.p-inline-message-icon {
		color: #73000c;
	}
}
.p-inline-message.p-inline-message-icon-only {
	.p-inline-message-icon {
		margin-right: 0;
	}
}
.p-message {
	margin: 1rem 0;
	border-radius: 3px;
	.p-message-wrapper {
		padding: 1rem 1.5rem;
	}
	.p-message-close {
		width: 2rem;
		height: 2rem;
		border-radius: 50%;
		background: transparent;
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		&:hover {
			background: rgba(255, 255, 255, 0.3);
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: 0 0 0 0.2rem var(--shadow-color);
		}
	}
	.p-message-text {
		font-size: 1rem;
		font-weight: 500;
	}
	.p-message-icon {
		font-size: 1.5rem;
		margin-right: 0.5rem;
	}
	.p-message-summary {
		font-weight: 700;
	}
	.p-message-detail {
		margin-left: 0.5rem;
	}
}
.p-message.p-message-info {
	background: #B3E5FC;
	border: solid #0891cf;
	border-width: 0 0 0 6px;
	color: #044868;
	.p-message-icon {
		color: #044868;
	}
	.p-message-close {
		color: #044868;
	}
}
.p-message.p-message-success {
	background: #C8E6C9;
	border: solid #439446;
	border-width: 0 0 0 6px;
	color: #224a23;
	.p-message-icon {
		color: #224a23;
	}
	.p-message-close {
		color: #224a23;
	}
}
.p-message.p-message-warn {
	background: #FFECB3;
	border: solid #d9a300;
	border-width: 0 0 0 6px;
	color: #6d5100;
	.p-message-icon {
		color: #6d5100;
	}
	.p-message-close {
		color: #6d5100;
	}
}
.p-message.p-message-error {
	background: #FFCDD2;
	border: solid #e60017;
	border-width: 0 0 0 6px;
	color: #73000c;
	.p-message-icon {
		color: #73000c;
	}
	.p-message-close {
		color: #73000c;
	}
}
.p-toast {
	opacity: 0.9;
	.p-toast-message {
		margin: 0 0 1rem 0;
		box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
		border-radius: 3px;
		.p-toast-message-content {
			padding: 1rem;
			border-width: 0 0 0 6px;
			.p-toast-message-text {
				margin: 0 0 0 1rem;
			}
			.p-toast-message-icon {
				font-size: 2rem;
			}
			.p-toast-summary {
				font-weight: 700;
			}
			.p-toast-detail {
				margin: 0.5rem 0 0 0;
			}
		}
		.p-toast-icon-close {
			width: 2rem;
			height: 2rem;
			border-radius: 50%;
			background: transparent;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			&:hover {
				background: rgba(255, 255, 255, 0.3);
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: 0 0 0 0.2rem var(--shadow-color);
			}
		}
	}
	.p-toast-message.p-toast-message-info {
		background: #B3E5FC;
		border: solid #0891cf;
		border-width: 0 0 0 6px;
		color: #044868;
		.p-toast-message-icon {
			color: #044868;
		}
		.p-toast-icon-close {
			color: #044868;
		}
	}
	.p-toast-message.p-toast-message-success {
		background: #C8E6C9;
		border: solid #439446;
		border-width: 0 0 0 6px;
		color: #224a23;
		.p-toast-message-icon {
			color: #224a23;
		}
		.p-toast-icon-close {
			color: #224a23;
		}
	}
	.p-toast-message.p-toast-message-warn {
		background: #FFECB3;
		border: solid #d9a300;
		border-width: 0 0 0 6px;
		color: #6d5100;
		.p-toast-message-icon {
			color: #6d5100;
		}
		.p-toast-icon-close {
			color: #6d5100;
		}
	}
	.p-toast-message.p-toast-message-error {
		background: #FFCDD2;
		border: solid #e60017;
		border-width: 0 0 0 6px;
		color: #73000c;
		.p-toast-message-icon {
			color: #73000c;
		}
		.p-toast-icon-close {
			color: #73000c;
		}
	}
}
.p-galleria {
	.p-galleria-close {
		margin: 0.5rem;
		background: transparent;
		color: var(--surface-b);
		width: 4rem;
		height: 4rem;
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		border-radius: 50%;
		.p-galleria-close-icon {
			font-size: 2rem;
		}
		&:hover {
			background: rgba(255, 255, 255, 0.1);
			color: var(--surface-b);
		}
	}
	.p-galleria-item-nav {
		background: transparent;
		color: var(--surface-b);
		width: 4rem;
		height: 4rem;
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		border-radius: 3px;
		margin: 0 0.5rem;
		.p-galleria-item-prev-icon {
			font-size: 2rem;
		}
		.p-galleria-item-next-icon {
			font-size: 2rem;
		}
		&:not(.p-disabled) {
			&:hover {
				background: rgba(255, 255, 255, 0.1);
				color: var(--surface-b);
			}
		}
	}
	.p-galleria-caption {
		background: rgba(0, 0, 0, 0.5);
		color: var(--surface-b);
		padding: 1rem;
	}
	.p-galleria-indicators {
		padding: 1rem;
		.p-galleria-indicator {
			button {
				background-color: var(--surface-c);
				width: 1rem;
				height: 1rem;
				transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
				border-radius: 50%;
				&:hover {
					background: var(--surface-d);
				}
			}
		}
		.p-galleria-indicator.p-highlight {
			button {
				background: var(--background-panel);
				color: var(--text-color);
				background-color: var(--primary-color);
			}
		}
	}
	.p-galleria-thumbnail-container {
		background: rgba(0, 0, 0, 0.9);
		padding: 1rem 0.25rem;
		.p-galleria-thumbnail-prev {
			margin: 0.5rem;
			background-color: transparent;
			color: var(--surface-b);
			width: 2rem;
			height: 2rem;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			border-radius: 50%;
			&:hover {
				background: rgba(255, 255, 255, 0.1);
				color: var(--surface-b);
			}
		}
		.p-galleria-thumbnail-next {
			margin: 0.5rem;
			background-color: transparent;
			color: var(--surface-b);
			width: 2rem;
			height: 2rem;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			border-radius: 50%;
			&:hover {
				background: rgba(255, 255, 255, 0.1);
				color: var(--surface-b);
			}
		}
		.p-galleria-thumbnail-item-content {
			transition: box-shadow 0.2s;
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: 0 0 0 0.2rem var(--shadow-color);
			}
		}
	}
}
.p-galleria.p-galleria-indicators-bottom {
	.p-galleria-indicator {
		margin-right: 0.5rem;
	}
}
.p-galleria.p-galleria-indicators-top {
	.p-galleria-indicator {
		margin-right: 0.5rem;
	}
}
.p-galleria.p-galleria-indicators-left {
	.p-galleria-indicator {
		margin-bottom: 0.5rem;
	}
}
.p-galleria.p-galleria-indicators-right {
	.p-galleria-indicator {
		margin-bottom: 0.5rem;
	}
}
.p-galleria.p-galleria-indicator-onitem {
	.p-galleria-indicators {
		background: rgba(0, 0, 0, 0.5);
		.p-galleria-indicator {
			button {
				background: rgba(255, 255, 255, 0.4);
				&:hover {
					background: rgba(255, 255, 255, 0.6);
				}
			}
		}
		.p-galleria-indicator.p-highlight {
			button {
				background: var(--background-panel);
				color: var(--text-color);
			}
		}
	}
}
.p-galleria-mask.p-component-overlay {
	background-color: rgba(0, 0, 0, 0.9);
}
.p-avatar {
	background-color: var(--surface-d);
	border-radius: 3px;
}
.p-avatar.p-avatar-lg {
	width: 3rem;
	height: 3rem;
	font-size: 1.5rem;
	.p-avatar-icon {
		font-size: 1.5rem;
	}
}
.p-avatar.p-avatar-xl {
	width: 4rem;
	height: 4rem;
	font-size: 2rem;
	.p-avatar-icon {
		font-size: 2rem;
	}
}
.p-avatar-group {
	.p-avatar {
		border: 2px solid var(--surface-0);
	}
}
.p-badge {
	background: var(--primary-color);
	color: var(--primary-color-text);
	font-size: 0.75rem;
	font-weight: 700;
	min-width: 1.5rem;
	height: 1.5rem;
	line-height: 1.5rem;
}
.p-badge.p-badge-secondary {
	background-color: var(--button-secondary);
	color: var(--surface-0);
}
.p-badge.p-badge-success {
	background-color: #689F38;
	color: var(--surface-0);
}
.p-badge.p-badge-info {
	background-color: #0288D1;
	color: var(--surface-0);
}
.p-badge.p-badge-warning {
	background-color: #FBC02D;
	color: var(--primary-color-text);
}
.p-badge.p-badge-danger {
	background-color: #D32F2F;
	color: var(--surface-0);
}
.p-badge.p-badge-lg {
	font-size: 1.125rem;
	min-width: 2.25rem;
	height: 2.25rem;
	line-height: 2.25rem;
}
.p-badge.p-badge-xl {
	font-size: 1.5rem;
	min-width: 3rem;
	height: 3rem;
	line-height: 3rem;
}
.p-blockui.p-component-overlay {
	background: rgba(0, 0, 0, 0.4);
}
.p-chip {
	background-color: var(--surface-d);
	color: var(--text-color);
	border-radius: 16px;
	padding: 0 0.5rem;
	.p-chip-text {
		line-height: 1.5;
		margin-top: 0.25rem;
		margin-bottom: 0.25rem;
	}
	.p-chip-icon {
		margin-right: 0.5rem;
	}
	.pi-chip-remove-icon {
		margin-left: 0.5rem;
		border-radius: 3px;
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: 0 0 0 0.2rem var(--shadow-color);
		}
	}
	img {
		width: 2rem;
		height: 2rem;
		margin-left: -0.5rem;
		margin-right: 0.5rem;
	}
}
.p-inplace {
	.p-inplace-display {
		padding: 0.5rem 0.5rem;
		border-radius: 3px;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		&:not(.p-disabled) {
			&:hover {
				background: var(--surface-c);
				color: var(--text-color);
			}
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: 0 0 0 0.2rem var(--shadow-color);
		}
	}
}
.p-progressbar {
	border: 0 none;
	height: 1.5rem;
	background: var(--surface-d);
	border-radius: 3px;
	.p-progressbar-value {
		border: 0 none;
		margin: 0;
		background: var(--primary-color);
	}
	.p-progressbar-label {
		color: var(--text-color);
		line-height: 1.5rem;
	}
}
.p-scrolltop {
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
	transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
	.p-scrolltop-icon {
		font-size: 1.5rem;
		color: var(--surface-b);
	}
}
.p-scrolltop.p-link {
	background: rgba(0, 0, 0, 0.7);
	&:hover {
		background: rgba(0, 0, 0, 0.8);
	}
}
.p-skeleton {
	background-color: var(--surface-c);
	border-radius: 3px;
	&:after {
		background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
	}
}
.p-tag {
	background: var(--primary-color);
	color: var(--primary-color-text);
	font-size: 0.75rem;
	font-weight: 700;
	padding: 0.25rem 0.4rem;
	border-radius: 3px;
	.p-tag-icon {
		margin-right: 0.25rem;
		font-size: 0.75rem;
	}
}
.p-tag.p-tag-success {
	background-color: #689F38;
	color: var(--surface-0);
}
.p-tag.p-tag-info {
	background-color: #0288D1;
	color: var(--surface-0);
}
.p-tag.p-tag-warning {
	background-color: #FBC02D;
	color: var(--primary-color-text);
}
.p-tag.p-tag-danger {
	background-color: #D32F2F;
	color: var(--surface-0);
}
.p-terminal {
	background: var(--surface-0);
	color: var(--text-color);
	border: 1px solid var(--surface-d);
	padding: 1rem;
	.p-terminal-input {
		font-size: 1rem;
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	}
}
@media screen and (max-width: 769px) {
	.p-datepicker {
		table {
			th {
				padding: 0;
			}
			td {
				padding: 0;
			}
		}
	}
}
@media screen and (max-width: 960px) {
	.p-menubar {
		position: relative;
		.p-menubar-button {
			display: flex;
			width: 2rem;
			height: 2rem;
			color: var(--text-color-secondary);
			border-radius: 50%;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			&:hover {
				color: var(--text-color-secondary);
				background: var(--surface-c);
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				box-shadow: 0 0 0 0.2rem var(--shadow-color);
			}
		}
		.p-menubar-root-list {
			position: absolute;
			display: none;
			padding: 0.25rem 0;
			background: var(--surface-0);
			border: 0 none;
			box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
			width: 100%;
			.p-menu-separator {
				border-top: 1px solid var(--surface-d);
				margin: 0.25rem 0;
			}
			.p-submenu-icon {
				font-size: 0.875rem;
			}
			>.p-menuitem {
				width: 100%;
				position: static;
				>.p-menuitem-link {
					padding: 0.75rem 1rem;
					color: var(--text-color);
					border-radius: 0;
					transition: box-shadow 0.2s;
					user-select: none;
					.p-menuitem-text {
						color: var(--text-color);
					}
					.p-menuitem-icon {
						color: var(--text-color-secondary);
						margin-right: 0.5rem;
					}
					.p-submenu-icon {
						color: var(--text-color-secondary);
					}
					&:not(.p-disabled) {
						&:hover {
							background: var(--surface-c);
							.p-menuitem-text {
								color: var(--text-color);
							}
							.p-menuitem-icon {
								color: var(--text-color-secondary);
							}
							.p-submenu-icon {
								color: var(--text-color-secondary);
							}
						}
					}
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						box-shadow: inset 0 0 0 0.15rem var(--shadow-color);
					}
					>.p-submenu-icon {
						margin-left: auto;
						transition: transform 0.2s;
					}
				}
			}
			>.p-menuitem.p-menuitem-active {
				>.p-menuitem-link {
					>.p-submenu-icon {
						transform: rotate(-180deg);
					}
				}
			}
			.p-submenu-list {
				width: 100%;
				position: static;
				box-shadow: none;
				border: 0 none;
				.p-submenu-icon {
					transition: transform 0.2s;
					transform: rotate(90deg);
				}
				.p-menuitem-active {
					>.p-menuitem-link {
						>.p-submenu-icon {
							transform: rotate(-90deg);
						}
					}
				}
			}
			.p-menuitem {
				width: 100%;
				position: static;
			}
			ul {
				li {
					a {
						padding-left: 2.25rem;
					}
					ul {
						li {
							a {
								padding-left: 3.75rem;
							}
							ul {
								li {
									a {
										padding-left: 5.25rem;
									}
									ul {
										li {
											a {
												padding-left: 6.75rem;
											}
											ul {
												li {
													a {
														padding-left: 8.25rem;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.p-menubar.p-menubar-mobile-active {
		.p-menubar-root-list {
			display: flex;
			flex-direction: column;
			top: 100%;
			left: 0;
			z-index: 1;
		}
	}
}
