////
/// A element
///
/// Here we got the a styles
////
a {
  color: color('terciary');

    &:focus, &:active {
        outline: 0;
    }

    &:hover {
      color: color('terciary');
    }
}
