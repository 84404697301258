/*-------------------------------------------------------------
	#TABVIEW
-----------------------------------------------------------------*/

  .p-tabview-nav-link[aria-selected="true"] {
	color: var(--surface-d) !important;
	border-bottom: 3px white solid !important;
  }
  
  .p-tabview-nav-link[aria-selected="false"] {
	// border-width: 3px 3px 0px 3px !important;
	border-color: var(--surface-d) !important;
	color: var(--surface-d) !important;
	margin: 0;
  }
  .p-tabview-nav-link {
	border-top-right-radius: 15px !important;
	border-top-left-radius: 15px !important;
  }
  
  .p-tabview-title, .p-tabview-left-icon {
	color: var(--surface-900) !important;
  }