///*-------------------------------------------------------------------------
//   # FRONT MODULES CONFIG
//--------------------------------------------------------------------------*/
//	CONTENT THIS FILE:
//		# IMPORT FILE CONFIG SCSS
//      # SETTINGS
//		# TOOLS
//      # GENERIC
//		# BASE
//		# OBJETCS
//      # COMPONENTS
//      # TRUMPS
//*--------------------------------------------------------
//    # SETTINGS
//----------------------------------------------------------*/
@import 'scss/brands/l1/00-settings/_settings__basics';
@import 'scss/brands/l1/00-settings/_settings__colors';
@import 'scss/brands/l1/00-settings/_settings__responsive';
@import 'scss/brands/l1/00-settings/_settings__headings';
@import 'scss/brands/l1/00-settings/_settings__grid-reflex';
@import 'scss/brands/l1/00-settings/_settings__trumps';
@import 'scss/brands/l1/00-settings/_settings__objects';

//*----------------------------------------------------------
//    # TOOLS
//----------------------------------------------------------*/
@import 'scss/01-tools/_tools__border-radius';
@import 'scss/01-tools/_tools__box-shadow';
@import 'scss/01-tools/_tools__colors';
@import 'scss/01-tools/_tools__fonts';
@import 'scss/01-tools/_tools__pixel-to-em';
@import 'scss/01-tools/_tools__pixel-to-rem';
@import 'scss/01-tools/_tools__responsive';
@import 'scss/01-tools/_tools__transform';
@import 'scss/01-tools/_tools__transition';
@import 'scss/01-tools/_tools__truncate';
@import 'scss/01-tools/_tools__user-select';
@import 'scss/01-tools/_tools__z-index';
//*----------------------------------------------------------
//     # GENERIC
// ----------------------------------------------------------*/
// Import files grid reflex
@import 'scss/02-generic/reflex/_mixins';
@import 'scss/02-generic/reflex/_grid';
@import 'scss/02-generic/reflex/_helpers';
// End import files grid reflex
@import "scss/02-generic/_generic__accessible";
@import "scss/02-generic/_generic__box-sizing";
@import "scss/brands/l1/02-generic/_generic__fonts";
@import "scss/02-generic/_generic__reset";
//*----------------------------------------------------------
//     # BASE
// ----------------------------------------------------------*/
@import 'scss/brands/l1/03-elements/_elements__a';
@import 'scss/brands/l1/03-elements/_elements__body';
@import 'scss/brands/l1/03-elements/_elements__button';
@import 'scss/brands/l1/03-elements/_elements__headings';
@import 'scss/brands/l1/03-elements/_elements__html';
@import 'scss/brands/l1/03-elements/_elements__image';
@import 'scss/brands/l1/03-elements/_elements__lists';
// @import '03-elements/_elements__scrollbar';

@import 'scss/custom-theme.scss';
// /*----------------------------------------------------------
//     # OBJECTS
// ----------------------------------------------------------*/
.l1-brand{
  @import 'scss/brands/l1/04-objects/_objects__alert';
  @import 'scss/brands/l1/04-objects/_objects__btn';
  @import 'scss/brands/l1/04-objects/_objects__checkbox';
  @import 'scss/brands/l1/04-objects/_objects__radio';
  @import 'scss/brands/l1/04-objects/_objects__txt';
  @import 'scss/brands/l1/04-objects/_objects__switch';
  @import 'scss/brands/l1/04-objects/_objects__inputtext';
  @import 'scss/brands/l1/04-objects/_objects__inputnumber.scss';
  @import 'scss/brands/l1/04-objects/_objects__password';
  @import 'scss/brands/l1/04-objects/_objects__overlay-panel.scss';
  @import 'scss/brands/l1/04-objects/_objects__link';
  @import 'scss/brands/l1/04-objects/_objects__progressbar';
  @import 'scss/brands/l1/04-objects/_objects__check';
  @import 'scss/brands/l1/04-objects/_objects__dropdown';
  @import 'scss/brands/l1/04-objects/_objects__shadow-wrapper';
  @import 'scss/brands/l1/04-objects/_objects__radiobutton';
  @import 'scss/brands/l1/04-objects/_objects__slider';
  @import 'scss/brands/l1/04-objects/_objects__box';
  @import 'scss/brands/l1/04-objects/_objects__scrollpanel';
  @import 'scss/brands/l1/04-objects/_objects__tabview';
  @import 'scss/brands/l1/05-components/timeline-chart.scss';

// /*----------------------------------------------------------
//     # COMPONENTS
// ----------------------------------------------------------*/

  @import 'scss/06-trumps/trumps';
}
