/*-------------------------------------------------------------
    #DROPDOWN
-----------------------------------------------------------------*/

.o-dropdown {
  .p-dropdown {
    border-radius: 0;
    border-color: color('quaternary');
    width: 100%;
    max-width: rem(260);
  }

  .p-dropdown-label {
    color: color('black');
  }

  .p-dropdown-panel {
    border-radius: 0;

    .p-dropdown-items {
      .p-dropdown-item {
        color: color('black');

        &.p-highlight {
          background-color: color('primary');
          color: color('white');
        }
      }
    }
  }

  .p-dropdown-trigger-icon {
    color: color('black');
    font-size: rem(12);
  }

  .p-dropdown-items-wrapper {
    font-family: $ns-font-default;
  }

  &__label {
    display: block;
    font-size: rem(16);
    color: color('quaternary');

    &--inline {
      display: inline-block;
      margin-right: rem(8);
    }

    &--float {
      color: color('terciary');
      font-size: rem(12);
    }
  }

  &__wrapper {
    width: 100%;

    .o-dropdown__label {
      margin-top: rem(3);
    }
  }

  &--w-100 {
    width: 100%;
    .p-dropdown {
      width: 100%;
      max-width: none;
    }
  }

  &--w-50 {
    width: 100%;
    .p-dropdown {
      width: 100%;
      max-width: none;
    }
  }

  &--lite {
    .p-dropdown {
      border-width: 0 0 rem(2) 0;
      border-color: color('terciary');

      &:not(.p-disabled):hover {
        border-color: color('terciary');
      }
    }

    .p-dropdown-label {
      padding: rem(4) rem(8) rem(4) 0;
    }
  }
}

.l1 {
  .o-dropdown {
    .p-dropdown {
      border-radius: 0;
      border-color: color('quaternary');
      width: 100%;
      max-width: rem(260);
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:not(.p-disabled) {
        &:hover {
          border-color: color('terciary');
        }

        &.p-focus {
          box-shadow: none;
        }
      }
    }

    .p-dropdown-label {
      color: color('black');
    }

    .p-dropdown-panel {
      border-radius: 0;

      .p-dropdown-items {
        .p-dropdown-item {
          color: color('black');

          &.p-highlight {
            background-color: color('primary');
            color: color('white');
          }
        }
      }
    }

    .p-dropdown-trigger-icon {
      color: color('black');
      font-size: rem(12);
    }

    .p-dropdown-items-wrapper {
      font-family: $ns-font-default;
    }

    &__label {
      display: block;
      font-size: rem(16);
      color: color('quaternary');
    }
  }
}

@include media-query(sm) {
  .o-dropdown {
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 50%;
      padding: 0 rem(20);

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      .o-dropdown__label {
        width: 100%;
      }
    }
  }
}
