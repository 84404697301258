// -------------------------
// You can optionally namespace reflex classes to avoid clashing with other css frameworks
// e.g. setting $reflex-prefix as "reflex-" here would cause col-sm-6 to become reflex-col-sm-6
// Example: $reflex-prefix: reflex-;
// By default this is an empty string
$reflex-prefix      :   l1- !default;


// Legacy support
// -------------------------
// Reflex adds a lot of css propertie to support older browsers that don't support flexbox layout.
// If you prefer to use this as a purely flexbox grid without legacy support then set this to false
$legacy-support     :   true !default;

// Responsive visibility helpers
// -------------------------
// Reflex has some generic visibility classes to hide elements at different breakpoints.
// If you want to disable these then set this to false
$visibility-helpers :   true !default;

// Grid
// -------------------------
$reflex-columns     :   12 !default; // number of columns

// Breakpoints
// -------------------------
$reflex-xs          :   $breakpoint-xs+px !default;
$reflex-sm          :   $breakpoint-sm+px !default;
$reflex-md          :   $breakpoint-md+px !default;
$reflex-lg          :   $breakpoint-lg+px !default;
$reflex-xlg         :   $breakpoint-xl+px !default;

// Spacing
// -------------------------
$reflex-grid-spacing: 8px !default;
