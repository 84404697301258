/*-------------------------------------------------------------
	#RADIOBUTTON
-----------------------------------------------------------------*/

.o-radiobutton {
  &.p-radiobutton {
    .p-radiobutton-box {
      border: rem(2) solid color('grey-1');

      &.p-highlight {
        border-color: color('terciary');
        background: color('terciary');
      }

      &:not(.p-disabled):not(.p-highlight):hover {
        border-color: color('terciary');
      }

      &.p-highlight:not(.p-disabled):hover {
        border-color: color('terciary');
        background: color('terciary');
      }

      &:not(.p-disabled).p-focus {
        box-shadow: 0 0 0 0.2rem color('terciary', 0.4);
      }

      .p-radiobutton-icon {
        background-color: color('white');
      }
    }
  }
}

.l1 {
  .o-radiobutton {
    &__label {
      margin-left: rem(10);
    }

    &--block {
      display: block;
    }

    &--mb {
      margin-bottom: rem(16);
    }

    &--mb-big {
      margin-bottom: rem(27);
    }

    .p-radiobutton {
      .p-radiobutton-box {
        border: rem(2) solid color('grey-1');

        &.p-highlight {
          border-color: color('terciary');
          background: color('terciary');
        }

        &:not(.p-disabled):not(.p-highlight):hover {
          border-color: color('terciary');
        }

        &.p-highlight:not(.p-disabled):hover {
          border-color: color('terciary');
          background: color('terciary');
        }

        &:not(.p-disabled).p-focus {
          box-shadow: 0 0 0 0.2rem color('terciary', 0.4);
        }

        .p-radiobutton-icon {
          background-color: color('white');
        }
      }
    }
  }
}
