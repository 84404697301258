/*-------------------------------------------------------------
    #BOX
-----------------------------------------------------------------*/
.o-box {
  height: 100%;
  position: relative;
  overflow: hidden;
  clip-path: polygon(100% 0%, 100% 100%, 100% 100%, 0% 100%, 0% 13%, 13% 0%);

  &:before {
    content: '';
    position: absolute;
    top: rem(-33);
    left: rem(-33);
    width: rem(65);
    height: rem(65);
    transform: rotate(45deg);
    background-color: color('primary');
  }

  figure {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      display: block;
    }
  }
}

@include media-query(xl) {
  .o-box {
    height: rem(456);
  }
}
