/*-------------------------------------------------------------
	#SHADOW WRAPPER
-----------------------------------------------------------------*/

.o-shadow-wrapper {
  overflow: hidden;

  &__shadow {
    position: relative;
  }

  &__height {
    min-height: calc(100vh - 759px);
  }

  &__height-short {
    min-height: calc(100vh - 683px);
  }

  &__height-medium {
    min-height: calc(100vh - 806px);
  }

  &__height-large {
    min-height: calc(100vh - 279px);
  }

  &__height-xlarge {
    min-height: calc(100vh - 113px);
  }
}

@include media-query(sm) {
  .o-shadow-wrapper {
    &__height {
      min-height: calc(100vh - 721px);
    }

    &__height-short {
      min-height: calc(100vh - 628px);
    }

    &__height-medium {
      min-height: calc(100vh - 735px);
    }

    &__height-large {
      min-height: calc(100vh - 249px);
    }

    &__height-xlarge {
      min-height: calc(100vh - 113px);
    }
  }
}
