////
/// BODY element
///
/// Here we got the body styles
////
body {
	position            		:	relative;
	font-family         		:	$ns-font-default;
	color               		:	color('black');
	background-color    		:	color('white');
	-webkit-text-size-adjust	:	100%;
  	height                  	: 	100%;
}
