/*-------------------------------------------------------------
	#SWITCH
-----------------------------------------------------------------*/

.o-switch {
  .p-inputswitch {
    width: rem(37);
    height: rem(14);

    &.p-inputswitch-checked {
      .p-inputswitch-slider {
        background: color('primary');
      }

      &:not(.p-disabled):hover .p-inputswitch-slider {
        background: color('primary');
      }
    }
  }

  .p-inputswitch-slider {
    width: rem(37);
    height: rem(14);

    &::before {
      border: 1px solid color('grey-1');
      box-shadow: 0px 3px 6px #00000029;
      left: 0;
    }
  }

  &__label {
    margin-left: rem(5);
    font-size: rem(14);
    color: color('quaternary');
    display: inline;
  }

  &--blue {
    .p-inputswitch {
      &.p-inputswitch-checked {
        .p-inputswitch-slider {
          background: color('terciary');
        }

        &:not(.p-disabled):hover .p-inputswitch-slider {
          background: color('terciary');
        }
      }
    }
  }
}
